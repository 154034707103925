import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { RichText } from "prismic-reactjs"
import Image from "../../elements/image/image"
import Styles from "./tabs-trio.module.scss"
import "./react-tabs.scss"

const TabsTrio = ({
  image,
  sharp,
  blueTitle,
  blueText,
  greenTitle,
  greenText,
  redTitle,
  redText,
}) => {
  return (
    <div className={cn(Styles.tabs_trio, "tabs-trio")}>
      <div className={Styles.tabs_trio__inner}>
        {image && sharp.childImageSharp && (
          <div className={Styles.tabs_trio__media}>
            <Image sharp={sharp} alt={image.alt || "Image"} />
          </div>
        )}

        {!sharp.childImageSharp && image && (
          <div className={Styles.tabs_trio__media}>
            <Image static src={image.url} alt={"test"} />
          </div>
        )}
        <div className={Styles.tabs_trio__tabs}>
          <Tabs>
            <TabList className={Styles.tabs_trio__list}>
              <Tab className={Styles.tabs_trio__tab}>{blueTitle}</Tab>
              <Tab className={Styles.tabs_trio__tab}>{greenTitle}</Tab>
              <Tab className={Styles.tabs_trio__tab}>{redTitle}</Tab>
            </TabList>
            <TabPanel className={Styles.tabs_trio__panel}>
              <div className={Styles.tabs_trio__inner}>
                <RichText render={blueText} />
              </div>
            </TabPanel>
            <TabPanel className={Styles.tabs_trio__panel}>
              <div className={Styles.tabs_trio__inner}>
                <RichText render={greenText} />
              </div>
            </TabPanel>
            <TabPanel className={Styles.tabs_trio__panel}>
              <div className={Styles.tabs_trio__inner}>
                <RichText render={redText} />
              </div>
            </TabPanel>
          </Tabs>
        </div>
      </div>
    </div>
  )
}

TabsTrio.propTypes = {
  image: PropTypes.object,
  sharp: PropTypes.object,
  blueTitle: PropTypes.string,
  blueText: PropTypes.array,
  greenTitle: PropTypes.string,
  greenText: PropTypes.array,
  redTitle: PropTypes.string,
  redText: PropTypes.array,
}

TabsTrio.defaultProps = {
  image: {},
  sharp: {},
  blueTitle: "",
  blueText: [],
  greenTitle: "",
  greenText: [],
  redTitle: "",
  redText: [],
}

export default TabsTrio
