import React, { useState } from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import Title from "../../elements/title/title"
import Wrapper from "../layout/wrapper/wrapper"
import Image from "../../elements/image/image"
import Overlay from "../../components/overlay/overlay"
import Video from "../../components/video/video"
import Styles from "./alternate-grid.module.scss"
import Play from "../../images/play-button.svg"

const AlternateGrid = props => {
  const [videoUrl, changeUrl] = useState("")
  const [overlay, triggerOverlay] = useState(false)

  const loadVideo = video => {
    triggerOverlay(true)
    changeUrl(video.embed_url)
  }

  const content = props.items.map((item, key) => {
    return (
      <li key={key.toString()} className={Styles.altGrid__itemWrapper}>
        <div
          className={`${Styles.altGrid__item} ${Styles.altGrid__itemText} ${
            key % 2 === 0 ? "" : Styles.altGrid__itemReverse
          }`}
        >
          <div className={Styles.altGrid__textWrapper}>
            <Title tag="h3" title={item.headline} />
            {item.description && <RichText render={item.description} />}
            {item.video && (
              <p
                className={Styles.altGrid__link}
                onClick={() => loadVideo(item.video)}
              >
                See how it works
                <img
                  className={Styles.altGrid__playButton}
                  src={Play}
                  alt={item.headline}
                />
              </p>
            )}
          </div>
        </div>

        {item.video && (
          <div
            className={`${Styles.altGrid__item} ${Styles.altGrid__itemVideo} ${Styles.altGrid__itemImage}`}
            onClick={() => loadVideo(item.video)}
          >
            {item.image && (
              <Image
                sharp={item.imageSharp}
                alt={item.image.alt || item.headline}
              />
            )}
          </div>
        )}

        {!item.video && (
          <div
            className={`${Styles.altGrid__item} ${Styles.altGrid__itemImage}`}
          >
            {item.image && (
              <Image
                sharp={item.imageSharp}
                alt={item.image.alt || item.headline}
              />
            )}
          </div>
        )}
      </li>
    )
  })

  return (
    <Wrapper>
      {overlay && (
        <Overlay closeAction={() => triggerOverlay(false)}>
          <Video url={videoUrl} />
        </Overlay>
      )}
      <ul className={Styles.altGrid}>{content}</ul>
    </Wrapper>
  )
}

AlternateGrid.propTypes = {
  items: PropTypes.array,
}

AlternateGrid.defaultProps = {
  items: [],
}

export default AlternateGrid
