import React from "react"
import Overview from "../../components/overview/overview"
import { getPageUrl } from "../../utils/helpers"

export default ({ isHero, slice }) => {
  const { theme, headline, summary, link, link_text } = slice.primary
  const buttons = slice.fields

  return (
    <Overview
      theme={theme}
      headline={headline}
      summary={summary}
      buttons={buttons}
      isHero={isHero}
      link={link ? getPageUrl(link) : null}
      linkText={link_text}
    />
  )
}
