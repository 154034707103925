import React, { useState } from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import Styles from "./info-card.module.scss"
import Image from "../../elements/image/image"

const InfoCard = ({ headline, subtitle, description, sharp, theme }) => {
  const [expanded, setExpanded] = useState(false)
  const isDarkTheme = theme === "dark"

  const expand = e => {
    e.preventDefault()
    setExpanded(!expanded)
  }

  return (
    <div className={cn(Styles.infoCard, isDarkTheme && Styles.infoCardDark)}>
      {sharp && (
        <div className={Styles.infoCard__image}>
          <Image sharp={sharp} alt={headline} />
        </div>
      )}

      <div className={`${Styles.infoCard__main}`}>
        <h4 className={`${Styles.infoCard__headline}`}>{headline}</h4>

        <div
          className={`${Styles.infoCard__content} ${
            expanded ? Styles.infoCard__contentExpanded : ""
          } ${subtitle ? Styles.infoCard__contentHasSubtitle : ""}`}
        >
          {subtitle && (
            <p
              className={cn(
                Styles.infoCard__subtitle,
                !description && Styles.infoCard__subtitleNodescription
              )}
            >
              {subtitle}
            </p>
          )}
          {description && <RichText render={description} />}
        </div>

        <p onClick={expand} className={Styles.infoCard__link}>
          {expanded ? "Read less" : "Read more"}
        </p>
      </div>
    </div>
  )
}

InfoCard.propTypes = {
  headline: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.array,
  sharp: PropTypes.object,
}

export default InfoCard
