import React from "react"
import PropTypes from "prop-types"
import InfoCard from "../info-card/info-card"
import Styles from "./features.module.scss"
import ButtonGroup from "../button-group/button-group"

const Features = ({ buttonTitle, buttonLink, items, theme }) => {
  const content = items.map((item, key) => {
    return (
      <li key={key.toString()} className={Styles.features__item}>
        <InfoCard
          theme={theme}
          headline={item.headline}
          subtitle={item.summary}
          description={item.text}
          sharp={item.iconSharp}
        />
      </li>
    )
  })

  return (
    <>
      <ul className={Styles.features}>{content}</ul>
      {buttonTitle && buttonLink && (
        <ButtonGroup buttons={[{ title: buttonTitle, link: buttonLink }]} />
      )}
    </>
  )
}

Features.propTypes = {
  buttonTitle: PropTypes.string,
  buttonLink: PropTypes.object,
  items: PropTypes.array,
  theme: PropTypes.string,
}

Features.defaultProps = {
  buttonTitle: "",
  buttonLink: {},
  items: [],
}

export default Features
