import React from "react"
import TextSummaryWithQuote from "../../components/text-summary-with-quote/text-summary-with-quote"

export default ({ slice }) => {
  const {
    headline,
    summary,
    author_position,
    quote,
    quote_author,
    summary_alignment,
  } = slice.primary

  return (
    <TextSummaryWithQuote
      headline={headline}
      summary={summary}
      authorPosition={author_position}
      quote={quote}
      quoteAuthor={quote_author}
      summaryAlignment={summary_alignment}
    />
  )
}
