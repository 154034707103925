import React from "react"
import PropTypes from "prop-types"
import StatCard from "../stat-card/stat-card"
import Styles from "./stat-cards.module.scss"

const StatCards = ({ items }) => {
  return (
    <ul className={Styles.statCards}>
      {items.map((item, i) => (
        <li key={i} className={Styles.statCards__item}>
          <StatCard stat={item.stat} description={item.description} />
        </li>
      ))}
    </ul>
  )
}

StatCards.propTypes = {
  items: PropTypes.array,
}

StatCards.defaultProps = {
  items: [],
}

export default StatCards
