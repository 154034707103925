import React from "react"
import PropTypes from "prop-types"
import GraphicInfoCard from "../graphic-info-card/graphic-info-card"
import Styles from "./features-with-graphics.module.scss"

const Features = ({ headline, items }) => {
  const content = items.map((item, key) => {
    return (
      <li key={key.toString()} className={Styles.graphicFeatures__item}>
        <GraphicInfoCard
          heading={item.heading}
          description={item.description}
          backgroundColor={item.background_color}
          sharp={item.imageSharp}
          linkObject={item?.read_more_link}
          readMoreLabel={item?.read_more_label}
        />
      </li>
    )
  })

  return (
    <>
      <h2 className={Styles.headline}>{headline}</h2>
      <ul className={Styles.graphicFeatures}>{content}</ul>
    </>
  )
}

Features.propTypes = {
  items: PropTypes.array,
}

Features.defaultProps = {
  items: [],
}

export default Features
