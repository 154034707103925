import React from "react"
import HeroGrid from "../../components/hero-grid/hero-grid"

export default ({ slice }) => {

  const { headline, summary } = slice.primary
  const buttons = slice.fields

  return <HeroGrid headline={headline} summary={summary} buttons={buttons} />
}
