import React from 'react';

const LiveStreamSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M34 30.5H0V6.5C0 3.186 2.686 0.5 6 0.5H40V24.5C40 27.814 37.314 30.5 34 30.5Z" fill="url(#paint0_linear_43_262)" />
            <path d="M0 39.5V30.5H13L0 39.5Z" fill="url(#paint1_linear_43_262)" />
            <path d="M21 21.5H9V12.5C9 10.843 10.343 9.5 12 9.5H24V18.5C24 20.157 22.657 21.5 21 21.5Z" fill="url(#paint2_linear_43_262)" />
            <path d="M24 13.5L26.536 10.964C27.473 10.027 28.745 9.5 30.071 9.5H31V21.5H30.071C28.745 21.5 27.473 20.973 26.535 20.036L24 17.5V13.5Z" fill="url(#paint3_linear_43_262)" />
            <defs>
                <linearGradient id="paint0_linear_43_262" x1="-11.534" y1="41.96" x2="72.129" y2="-28.242" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_43_262" x1="-4.265" y1="43.329" x2="12.79" y2="23.004" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_43_262" x1="16.5" y1="29.794" x2="16.5" y2="2.807" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_43_262" x1="18.517" y1="24.821" x2="38.103" y2="8.387" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default LiveStreamSvg;
