import React from "react"
import Registration from "../../components/registration"

export default ({ slice }) => {
  const { contact_text, netlify_form_id } = slice.primary

  return (
    <Registration contactText={contact_text} netlifyFormId={netlify_form_id} />
  )
}
