import React from "react"
import PropTypes from "prop-types"
import Styles from "./partners.module.scss"
import CtaPanel from "../cta-panel/cta-panel"
import Image from "../../elements/image/image"

const Partners = ({ headline, items }) => {
  const content = items.map((item, key) => {
    return (
      <li key={key.toString()} className={Styles.partners__item}>
        <Image sharp={item.imageSharp} alt={item.image.alt || item.company} />
      </li>
    )
  })

  return (
    <CtaPanel>
      <div className={Styles.partners}>
        <h3 className={Styles.partners__headline}>{headline}</h3>
        <ul className={Styles.partners__list}>{content}</ul>
      </div>
    </CtaPanel>
  )
}

Partners.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.array,
}

Partners.defaultProps = {
  headline: "",
  items: [],
}

export default Partners
