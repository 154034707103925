import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { RichText } from "prismic-reactjs"

import Styles from "./client-description.module.scss"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Lockup from "../lockup/lockup"

const ClientDescription = ({ headline, summary, region, industry }) => {
  return (
    <div className={cn(Styles.clientDescription)}>
      <div className={cn(Styles.clientDescription__container)}>
        <div className={cn(Styles.clientDescription__left)}>
          <div className={Styles.clientDescription__summary}>
            <Lockup
              tag="h2"
              title={headline ? headline : ""}
              text={
                summary
                  ? RichText.asText(summary, linkResolver, htmlSerializer)
                  : ""
              }
            />
          </div>
        </div>
        <div className={Styles.clientDescription__right}>
          {industry && (
            <div className={Styles.clientDescription__column}>
              <h3 className={Styles.clientDescription__categoryValue}>
                {industry}
              </h3>
              <h3 className={Styles.clientDescription__categoryHeading}>
                Industry
              </h3>
            </div>
          )}

          {region && (
            <div className={Styles.clientDescription__column}>
              <h3 className={Styles.clientDescription__categoryValue}>
                {region}
              </h3>
              <h3 className={Styles.clientDescription__categoryHeading}>
                Region
              </h3>
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

ClientDescription.propTypes = {
  headline: PropTypes.string,
  region: PropTypes.string,
  industry: PropTypes.string,
  summary: PropTypes.array,
}

ClientDescription.defaultProps = {
  headline: "",
  industry: null,
  region: null,
  summary: null,
}

export default ClientDescription
