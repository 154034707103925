import React from 'react';

const CCSvg = () => {
    return (
        <svg width="42" height="32" viewBox="0 0 42 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19 28.531C19 30.447 17.447 32 15.531 32C6.822 32 0 24.972 0 16C0 7.028 6.822 0 15.531 0H19V2.531C19 4.447 17.447 6 15.531 6C10.187 6 6 10.393 6 16C6 21.607 10.187 26 15.531 26H19V28.531Z" fill="url(#paint0_linear_269_63)" />
            <path d="M42 28.531C42 30.447 40.447 32 38.531 32C29.822 32 23 24.972 23 16C23 7.028 29.822 0 38.531 0H42V2.531C42 4.447 40.447 6 38.531 6C33.187 6 29 10.393 29 16C29 21.607 33.187 26 38.531 26H42V28.531Z" fill="url(#paint1_linear_269_63)" />
            <defs>
                <linearGradient id="paint0_linear_269_63" x1="2.484" y1="-9.76999" x2="24.332" y2="50.257" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_269_63" x1="25.484" y1="-9.76999" x2="47.332" y2="50.257" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default CCSvg;