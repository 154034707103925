import React from "react"
import PropTypes from "prop-types"
import InfoCard from "../info-card/info-card"
import Styles from "./stacks.module.scss"

const Stacks = ({ items }) => {
  const content = items.map((item, key) => {
    return (
      <li key={key.toString()} className={Styles.stacks__item}>
        <InfoCard
          headline={item.headline}
          description={item.text}
          sharp={item.iconSharp}
        />
      </li>
    )
  })

  return <ul className={Styles.stacks}>{content}</ul>
}

Stacks.propTypes = {
  items: PropTypes.array,
}

Stacks.defaultProps = {
  items: [],
}

export default Stacks
