import React from "react"
import PropTypes from "prop-types"
import Styles from "./results.module.scss"
import { RichText } from "prismic-reactjs"
import Title from "../../elements/title/title"
import Image from "../../elements/image/image"

const ResultCard = props => {
  return (
    <li
      className={`${Styles.results__itemWrapper} ${
        props.index === 2 ? Styles.results__itemWrapperCenter : ""
      }`}
    >
      <div className={`${Styles.results__item} ${Styles.results__itemImage}`}>
        {props.image && (
          <Image
            className={Styles.results__image}
            sharp={props.image}
            alt={props.image.alt || props.headline}
          />
        )}
      </div>

      <div
        className={`${Styles.results__textWrapper} ${
          props.index === 2 ? Styles.results__textWrapperFeatured : ""
        }`}
      >
        <Title tag="h2" title={props.headline} />
        {props.description && <RichText render={props.description} />}
      </div>
    </li>
  )
}

ResultCard.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.array,
  image: PropTypes.object,
  index: PropTypes.number,
}

export default ResultCard
