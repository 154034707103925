import React from "react"
import PropTypes from "prop-types"
import Styles from "./full-width-image.module.scss"
import Image from "../../elements/image/image"
import cn from "classnames"

const FullWidthImage = ({ image }) => {
  if (!image) return null
  return (
    <div className={Styles.root}>
      <Image sharp={image} />
    </div>
  )
}

FullWidthImage.propTypes = {
  image: PropTypes.object,
}

FullWidthImage.defaultProps = {
  image: {},
}

export default FullWidthImage
