import React from 'react';

const NoCodeSvg = () => {
    return (
        <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M40.426 18.561C39.996 16.87 39.332 15.277 38.461 13.815L40.131 9.54699C40.463 8.69899 40.261 7.73399 39.617 7.09L37.849 5.322L32.183 7.53899C30.721 6.669 29.129 6.004 27.438 5.574L25.6 1.374C25.236 0.539 24.411 0 23.5 0H21L18.561 5.574C16.87 6.004 15.277 6.66799 13.815 7.53899L9.54699 5.869C8.69899 5.537 7.73399 5.739 7.09 6.383L5.322 8.15099L7.53899 13.817C6.669 15.279 6.004 16.871 5.574 18.562L1.374 20.4C0.539 20.764 0 21.589 0 22.5V25L5.574 27.439C6.004 29.13 6.66799 30.723 7.53899 32.185L5.869 36.453C5.537 37.302 5.739 38.266 6.383 38.911L8.15099 40.679L13.817 38.462C15.279 39.332 16.871 39.997 18.562 40.427L20.4 44.627C20.764 45.461 21.589 46 22.5 46H25L27.439 40.426C29.13 39.996 30.723 39.332 32.185 38.461L36.453 40.131C37.302 40.463 38.266 40.261 38.911 39.617L40.679 37.849L38.462 32.183C39.332 30.721 39.997 29.129 40.427 27.438L44.627 25.6C45.461 25.236 46 24.411 46 23.5V21L40.426 18.561Z" fill="url(#paint0_linear_43_332)" />
            <path d="M17 14L33 23L17 32V14Z" fill="url(#paint1_linear_43_332)" />
            <defs>
                <linearGradient id="paint0_linear_43_332" x1="-13.763" y1="59.092" x2="73.442" y2="-26.52" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_43_332" x1="1.51901" y1="38.202" x2="34.534" y2="10.5" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default NoCodeSvg;
