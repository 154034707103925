import React from "react"
import Pricing from "../../components/pricing/pricing"

export default ({ slice }) => {
  const {
    panel_1_button_page_link,
    panel_1_button_text,
    panel_1_heading,
    panel_1_list,
    panel_1_subheading,
    panel_2_button_page_link,
    panel_2_button_text,
    panel_2_heading,
    panel_2_list,
    panel_2_subheading,
  } = slice.primary

  return (
    <Pricing
      panel1ButtonPageLink={panel_1_button_page_link}
      panel1ButtonText={panel_1_button_text}
      panel1Heading={panel_1_heading}
      panel1List={panel_1_list}
      panel1Subheading={panel_1_subheading}
      panel2ButtonPageLink={panel_2_button_page_link}
      panel2ButtonText={panel_2_button_text}
      panel2Heading={panel_2_heading}
      panel2List={panel_2_list}
      panel2Subheading={panel_2_subheading}
    />
  )
}
