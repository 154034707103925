import React from "react"
import PropTypes from "prop-types"

import AccordionItem from "./accordion-item"

const Accordion = ({ items }) => {
  return (
    <>
      {items.map((item, index) => {
        return <AccordionItem key={index} item={item} />
      })}
    </>
  )
}

Accordion.propTypes = {
  items: PropTypes.array,
}

Accordion.defaultProps = {
  items: [],
}

export default Accordion
