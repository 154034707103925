import React from "react"
import PropTypes from "prop-types"
import Image from "../../elements/image/image"
import Styles from "./logo-wall.module.scss"
import Wrapper from "../layout/wrapper/wrapper"
import Button from "../button/button"
import cn from "classnames"

const LogoWall = ({
  items,
  ctaText,
  ctaLink,
  ctaLinkParent,
  modifierClass,
}) => {
  const wrapperNoPadding = ["Demo"].includes(modifierClass)

  const content = items.map((item, key) => {
    return (
      <li key={key.toString()} className={cn(Styles.logoWall__item)}>
        <Image sharp={item.imageSharp} alt={item.image.alt || item.company} />
      </li>
    )
  })

  return (
    <Wrapper noPadding={wrapperNoPadding}>
      <ul className={cn(Styles.logoWall, Styles[modifierClass])}>{content}</ul>
      {ctaText && ctaLink && (
        <div className={Styles.ctaWrapper}>
          <Button internal={true} link={ctaLink}>
            {ctaText}
          </Button>
        </div>
      )}
    </Wrapper>
  )
}

LogoWall.propTypes = {
  items: PropTypes.array,
  modifierClass: PropTypes.string,
}

LogoWall.defaultProps = {
  items: [],
  modifierClass: "",
}

export default LogoWall
