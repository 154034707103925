import React from "react"
import Features from "../../components/features/features"

export default ({ slice }) => {
  const { button_title, button_link, theme } = slice.primary
  return (
    <Features
      theme={theme}
      buttonTitle={button_title}
      buttonLink={button_link}
      items={slice.fields}
    />
  )
}
