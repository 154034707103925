import React from 'react';

const PerfectSubtitles = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M46 28C46 32.87 44.42 37.36 41.75 41H6.25C3.58 37.36 2 32.87 2 28C2 15.85 11.85 6 24 6C36.15 6 46 15.85 46 28Z" fill="url(#paint0_linear_43_282)" />
            <path d="M35.22 13.95L32.38 16.79C30.04 15.04 27.14 14 24 14C16.28 14 10 20.28 10 28C10 30.15 10.47 32.21 11.4 34.12L7.81 35.88C6.61 33.42 6 30.77 6 28C6 18.07 14.08 10 24 10C28.24 10 32.14 11.48 35.22 13.95Z" fill="url(#paint1_linear_43_282)" />
            <path d="M42 28C42 30.77 41.39 33.42 40.19 35.88L36.6 34.12C37.53 32.21 38 30.15 38 28C38 24.86 36.96 21.96 35.21 19.62L36.9 17.93C37.34 17.49 37.61 16.95 37.72 16.38C40.39 19.51 42 23.57 42 28Z" fill="url(#paint2_linear_43_282)" />
            <path d="M36.9 17.93L27.86 26.97C27.5 25.59 26.41 24.5 25.03 24.14L36.19 12.98L36.9 13.69C38.07 14.86 38.07 16.76 36.9 17.93Z" fill="url(#paint3_linear_43_282)" />
            <path d="M24 32C26.2091 32 28 30.2091 28 28C28 25.7909 26.2091 24 24 24C21.7909 24 20 25.7909 20 28C20 30.2091 21.7909 32 24 32Z" fill="url(#paint4_linear_43_282)" />
            <defs>
                <linearGradient id="paint0_linear_43_282" x1="-0.107" y1="3.712" x2="57.714" y2="61.533" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_43_282" x1="41.156" y1="18.348" x2="-12.37" y2="27.786" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_43_282" x1="35.889" y1="10.922" x2="43.404" y2="53.545" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_43_282" x1="17.302" y1="37.327" x2="40.232" y2="9.99999" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint4_linear_43_282" x1="21.668" y1="41.227" x2="25.48" y2="19.604" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default PerfectSubtitles;
