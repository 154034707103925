import React from 'react';

const TedSvg = () => {
    return (
        <svg width="68" height="24" viewBox="0 0 68 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fill-rule="evenodd" clip-rule="evenodd" d="M21.8167 0V6.151H15.0537V24H7.61371V6.151H0.850708V0H21.8167ZM43.3007 0V6.141L30.3827 6.151V9.097H43.3007V14.827L30.3827 14.818V17.848H43.3007V24H22.9437V0H43.3007ZM56.7467 0C64.7947 0 67.6357 5.916 67.6357 11.966C67.6347 19.328 63.7117 24 55.2917 24H44.5397V0H56.7467ZM54.3467 6.151H51.9777V17.85H54.8857C59.5187 17.85 60.1957 14.119 60.1957 11.867C60.1957 10.354 59.7217 6.152 54.3457 6.152L54.3467 6.151Z" fill="currentColor" />
        </svg>
    );
};

export default TedSvg;
