import React from 'react';

const NabSvg = () => {
    return (
        <svg width="167" height="48" viewBox="0 0 167 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M55.398 32.8961L53.142 38.8321H53.126L53.366 32.8961H52.086L49.83 38.8001H49.814L50.07 32.8961H48.838L48.694 40.4961H50.102L52.278 34.7681H52.31L52.07 40.4961H53.478L56.598 32.8961H55.398Z" fill="currentColor" />
            <path d="M59.222 34.6081C58.63 34.6081 58.07 34.9121 57.654 35.3921L57.622 35.3761L58.15 32.8801H56.998L55.382 40.4801H56.534L57.35 36.6401C57.51 35.8881 58.038 35.5201 58.502 35.5201C59.078 35.5201 59.238 35.8081 59.078 36.5441L58.246 40.4801H59.398L60.31 36.1601C60.566 35.0081 60.102 34.6081 59.222 34.6081Z" fill="currentColor" />
            <path d="M63.51 34.6083C61.718 34.6083 61.046 36.2083 60.726 37.7123C60.166 40.3683 61.27 40.6403 62.198 40.6403C63.446 40.6403 64.374 39.8403 64.646 38.5763H63.558C63.478 38.9123 63.19 39.8563 62.374 39.8563C61.51 39.8563 61.622 38.8803 61.782 38.1123L61.846 37.7763H64.838L64.966 37.1683C65.334 35.5683 65.11 34.6083 63.51 34.6083ZM63.942 36.6723L63.878 36.9763H62.038L62.07 36.8643C62.23 36.1123 62.598 35.3923 63.334 35.3923C63.974 35.4083 64.102 35.9523 63.942 36.6723Z" fill="currentColor" />
            <path d="M69.03 34.6563C68.966 34.6403 68.886 34.6083 68.758 34.6083C68.102 34.6083 67.59 35.0083 67.206 35.6483H67.19L67.382 34.7523H66.23L65.014 40.4803H66.166L66.902 37.0563C67.126 35.9843 67.814 35.7603 68.326 35.7603C68.518 35.7603 68.71 35.8083 68.79 35.8243L69.03 34.6563Z" fill="currentColor" />
            <path d="M71.302 34.6083C69.51 34.6083 68.838 36.2083 68.518 37.7123C67.958 40.3683 69.046 40.6403 69.99 40.6403C71.238 40.6403 72.166 39.8403 72.438 38.5763H71.35C71.27 38.9123 70.982 39.8563 70.166 39.8563C69.302 39.8563 69.414 38.8803 69.574 38.1123L69.638 37.7763H72.63L72.758 37.1683C73.126 35.5683 72.902 34.6083 71.302 34.6083ZM71.734 36.6723L71.67 36.9763H69.83L69.862 36.8643C70.022 36.1123 70.39 35.3923 71.126 35.3923C71.782 35.4083 71.894 35.9523 71.734 36.6723Z" fill="currentColor" />
            <path d="M79.062 37.8881C78.918 38.5921 78.55 39.7121 77.542 39.7121C76.646 39.7121 76.374 39.0881 76.902 36.6401C77.43 34.1921 77.942 33.6321 78.838 33.6321C79.718 33.6001 79.766 34.4161 79.606 35.1041L79.574 35.2321H80.79L80.822 35.0881C80.982 34.3041 81.206 32.7041 79.046 32.7041C77.126 32.7041 76.262 33.9361 75.686 36.6561C75.11 39.3761 75.446 40.6081 77.366 40.6081C79.158 40.6081 79.99 39.2641 80.278 37.8881L80.31 37.7281H79.094L79.062 37.8881Z" fill="currentColor" />
            <path d="M83.446 34.6083C81.782 34.6083 81.062 35.8403 80.678 37.6163C80.294 39.3923 80.582 40.6243 82.166 40.6243C83.766 40.6243 84.566 39.3923 84.95 37.6163C85.318 35.8563 85.11 34.6083 83.446 34.6083ZM83.798 37.6323C83.494 39.0883 83.19 39.7923 82.342 39.7923C81.51 39.7923 81.51 39.0883 81.83 37.6323C82.102 36.3683 82.438 35.4723 83.27 35.4723C84.102 35.4723 84.07 36.3523 83.798 37.6323Z" fill="currentColor" />
            <path d="M88.838 34.6083C88.198 34.6083 87.59 34.9123 87.19 35.4243H87.158L87.302 34.7523H86.214L84.998 40.4803H86.15L86.966 36.6403C87.126 35.9043 87.654 35.5203 88.118 35.5203C88.694 35.5203 88.854 35.8083 88.694 36.5443L87.862 40.4803H89.014L89.926 36.1603C90.182 35.0083 89.718 34.6083 88.838 34.6083Z" fill="currentColor" />
            <path d="M91.654 39.0241L92.39 35.6001H93.318L93.494 34.7521H92.55L92.902 33.1201H91.75L91.398 34.7521H90.614L90.438 35.6001H91.222L90.47 39.1521C90.214 40.3201 90.566 40.5441 91.334 40.5441C91.702 40.5441 92.022 40.5121 92.278 40.4481L92.454 39.6001C92.342 39.6161 92.198 39.6321 92.038 39.6321C91.654 39.6321 91.558 39.4721 91.654 39.0241Z" fill="currentColor" />
            <path d="M95.878 34.6083C94.086 34.6083 93.414 36.2083 93.094 37.7123C92.534 40.3683 93.638 40.6403 94.566 40.6403C95.814 40.6403 96.742 39.8403 97.014 38.5763H95.926C95.846 38.9123 95.558 39.8563 94.742 39.8563C93.878 39.8563 93.99 38.8803 94.15 38.1123L94.214 37.7763H97.222L97.35 37.1683C97.686 35.5683 97.462 34.6083 95.878 34.6083ZM96.31 36.6723L96.246 36.9763H94.406L94.438 36.8643C94.598 36.1123 94.982 35.3923 95.702 35.3923C96.342 35.4083 96.454 35.9523 96.31 36.6723Z" fill="currentColor" />
            <path d="M101.222 34.6083C100.582 34.6083 99.974 34.9123 99.574 35.4243H99.542L99.686 34.7523H98.598L97.382 40.4803H98.534L99.35 36.6403C99.51 35.9043 100.038 35.5203 100.502 35.5203C101.078 35.5203 101.238 35.8083 101.078 36.5443L100.246 40.4803H101.398L102.31 36.1603C102.566 35.0083 102.086 34.6083 101.222 34.6083Z" fill="currentColor" />
            <path d="M105.286 33.1361H104.134L103.782 34.7681H102.998L102.822 35.6161H103.606L102.854 39.1681C102.598 40.3361 102.95 40.5601 103.718 40.5601C104.086 40.5601 104.406 40.5281 104.662 40.4641L104.838 39.6161C104.726 39.6321 104.582 39.6481 104.422 39.6481C104.022 39.6481 103.942 39.4881 104.022 39.0401L104.742 35.6161H105.686L105.862 34.7681H104.918L105.286 33.1361Z" fill="currentColor" />
            <path d="M111.286 37.8881C111.142 38.5921 110.774 39.7121 109.766 39.7121C108.87 39.7121 108.598 39.0881 109.126 36.6401C109.654 34.1921 110.166 33.6321 111.062 33.6321C111.942 33.6001 111.974 34.4161 111.83 35.1041L111.798 35.2321H113.014L113.046 35.0881C113.222 34.3041 113.43 32.7041 111.27 32.7041C109.334 32.7041 108.486 33.9361 107.91 36.6561C107.334 39.3761 107.67 40.6081 109.574 40.6081C111.366 40.6081 112.198 39.2641 112.486 37.8881L112.518 37.7281H111.302L111.286 37.8881Z" fill="currentColor" />
            <path d="M115.67 34.6083C114.006 34.6083 113.27 35.8403 112.902 37.6163C112.518 39.3923 112.806 40.6243 114.39 40.6243C115.99 40.6243 116.79 39.3923 117.174 37.6163C117.542 35.8563 117.334 34.6083 115.67 34.6083ZM116.022 37.6323C115.718 39.0883 115.414 39.7923 114.566 39.7923C113.734 39.7923 113.734 39.0883 114.038 37.6323C114.31 36.3683 114.646 35.4723 115.478 35.4723C116.326 35.4723 116.278 36.3523 116.022 37.6323Z" fill="currentColor" />
            <path d="M123.798 34.6083C123.11 34.6083 122.614 34.9283 122.166 35.4883C122.102 35.0083 121.846 34.6083 121.014 34.6083C120.47 34.6083 119.862 34.9123 119.462 35.4243H119.43L119.574 34.7523H118.502L117.286 40.4803H118.438L119.254 36.6403C119.414 35.9043 119.942 35.5203 120.39 35.5203C120.87 35.5203 121.03 35.8083 120.87 36.5443L120.038 40.4803H121.19L122.006 36.6403C122.166 35.9043 122.694 35.5203 123.158 35.5203C123.622 35.5203 123.782 35.8083 123.622 36.5443L122.79 40.4803H123.942L124.87 36.1283C125.11 35.0243 124.614 34.6083 123.798 34.6083Z" fill="currentColor" />
            <path d="M128.102 34.6083C126.31 34.6083 125.638 36.2083 125.318 37.7123C124.758 40.3683 125.862 40.6403 126.79 40.6403C128.038 40.6403 128.966 39.8403 129.238 38.5763H128.15C128.07 38.9123 127.782 39.8563 126.966 39.8563C126.102 39.8563 126.214 38.8803 126.374 38.1123L126.438 37.7763H129.43L129.558 37.1683C129.926 35.5683 129.702 34.6083 128.102 34.6083ZM128.534 36.6723L128.47 36.9763H126.63L126.662 36.8643C126.822 36.1123 127.206 35.3923 127.926 35.3923C128.582 35.4083 128.694 35.9523 128.534 36.6723Z" fill="currentColor" />
            <path d="M132.63 34.6083C131.446 34.6083 130.566 35.1043 130.342 36.1763C130.15 37.0883 130.422 37.5363 131.222 37.8243L132.086 38.1603C132.454 38.3043 132.63 38.5763 132.534 39.0083C132.422 39.5363 132.054 39.8403 131.414 39.8403C130.758 39.8403 130.598 39.4723 130.742 38.8163L130.774 38.6403H129.75L129.702 38.8483C129.462 39.9523 129.766 40.6243 131.206 40.6243C132.374 40.6243 133.366 40.0963 133.638 38.8803C133.83 37.9683 133.542 37.5203 132.742 37.2323L132.054 36.9763C131.494 36.7683 131.334 36.5763 131.43 36.1283C131.542 35.6003 131.942 35.3763 132.406 35.3763C132.998 35.3763 133.078 35.8083 132.982 36.2563L132.95 36.4003H134.038L134.086 36.1443C134.246 35.4723 134.086 34.6083 132.63 34.6083Z" fill="currentColor" />
            <path d="M137.654 39.0241L138.374 35.6001H139.318L139.494 34.7521H138.55L138.902 33.1201H137.75L137.398 34.7521H136.614L136.438 35.6001H137.222L136.47 39.1521C136.214 40.3201 136.55 40.5441 137.334 40.5441C137.702 40.5441 138.022 40.5121 138.278 40.4481L138.454 39.6001C138.342 39.6161 138.198 39.6321 138.038 39.6321C137.654 39.6321 137.558 39.4721 137.654 39.0241Z" fill="currentColor" />
            <path d="M141.83 34.6083C140.166 34.6083 139.43 35.8403 139.062 37.6163C138.678 39.3923 138.966 40.6243 140.55 40.6243C142.15 40.6243 142.95 39.3923 143.334 37.6163C143.702 35.8563 143.494 34.6083 141.83 34.6083ZM142.166 37.6323C141.862 39.0883 141.558 39.7923 140.71 39.7923C139.878 39.7923 139.878 39.0883 140.198 37.6323C140.47 36.3683 140.806 35.4723 141.638 35.4723C142.486 35.4723 142.438 36.3523 142.166 37.6323Z" fill="currentColor" />
            <path d="M148.134 32.8961H146.918L145.302 40.4961H149.462L149.67 39.4721H146.726L148.134 32.8961Z" fill="currentColor" />
            <path d="M152.726 32.8001H151.574L151.318 33.9521H152.47L152.726 32.8001Z" fill="currentColor" />
            <path d="M149.942 40.4963H151.078L152.31 34.7683H151.158L149.942 40.4963Z" fill="currentColor" />
            <path d="M155.686 34.7681H154.742L154.854 34.2721C154.934 33.8881 155.158 33.7281 155.59 33.7281H155.91L156.102 32.8481C155.942 32.8321 155.734 32.8161 155.478 32.8161C154.55 32.8161 153.942 33.1521 153.718 34.2401L153.606 34.7681H152.806L152.63 35.6161H153.43L152.39 40.4961H153.542L154.582 35.6161H155.526L155.686 34.7681Z" fill="currentColor" />
            <path d="M157.99 34.6083C156.198 34.6083 155.526 36.2083 155.206 37.7123C154.646 40.3683 155.75 40.6403 156.678 40.6403C157.926 40.6403 158.854 39.8403 159.126 38.5763H158.038C157.958 38.9123 157.67 39.8563 156.854 39.8563C155.99 39.8563 156.102 38.8803 156.262 38.1123L156.326 37.7763H159.318L159.446 37.1683C159.798 35.5683 159.59 34.6083 157.99 34.6083ZM158.422 36.6723L158.358 36.9763H156.518L156.55 36.8643C156.71 36.1123 157.094 35.3923 157.814 35.3923C158.454 35.4083 158.582 35.9523 158.422 36.6723Z" fill="currentColor" />
            <path d="M18.486 20.4162L5.95801 6.70418H1.28601V26.2081H5.65401V12.4962L18.166 26.2081H22.838V6.70418H18.486V20.4162Z" fill="currentColor" />
            <path d="M49.27 19.0082V26.2241H63.526C65.75 26.2241 67.51 25.8241 68.774 24.8961C70.038 23.9681 70.678 22.4961 70.678 20.7682C70.71 18.7042 69.91 17.0242 68.406 16.0642C69.43 15.1042 69.974 13.8242 69.974 12.3682C69.99 10.7202 69.398 9.28018 68.262 8.24018C67.126 7.20018 65.558 6.70418 63.718 6.70418H49.27V14.2722H51.734L49.27 19.0082ZM53.51 10.8162H63.894C64.582 10.8162 65.014 10.9922 65.334 11.2802C65.654 11.5842 65.798 11.9202 65.814 12.5122C65.798 13.0242 65.654 13.3602 65.254 13.7282C64.854 14.0802 64.406 14.2562 63.766 14.2562H53.51V10.8162ZM53.51 18.3842H64.006C64.934 18.3842 65.526 18.5922 65.846 18.8802C66.166 19.1682 66.358 19.5682 66.374 20.3362C66.358 20.9922 66.182 21.3282 65.798 21.6162C65.398 21.9042 64.662 22.1121 63.558 22.1121H53.51V18.3842Z" fill="currentColor" />
            <path d="M97.19 18.1602H108.95V26.2081H113.11V6.70418H108.95V14.0482H97.19V6.70418H93.03V26.2081H97.19V18.1602Z" fill="currentColor" />
            <path d="M133.75 8.73615C131.686 6.99214 129.094 6.11214 126.086 6.12814H126.07C123.094 6.12814 120.486 6.99214 118.39 8.73615C116.006 10.7041 114.758 13.3442 114.774 16.4321C114.758 19.5521 115.99 22.2081 118.358 24.1441C120.438 25.8721 123.046 26.7361 126.086 26.7361H126.102C129.11 26.7361 131.702 25.8881 133.766 24.1761C136.134 22.2241 137.35 19.5681 137.35 16.4321C137.366 13.3442 136.134 10.7201 133.75 8.73615ZM131.222 20.8481C129.974 21.9841 128.31 22.5601 126.086 22.5601C123.862 22.5601 122.182 21.9681 120.918 20.8481C119.654 19.7121 119.046 18.3041 119.046 16.4161C119.062 14.6081 119.67 13.2161 120.966 12.0321C122.262 10.8641 123.926 10.2881 126.086 10.2721C128.054 10.2721 129.622 10.8001 130.902 11.8081C132.39 13.0241 133.078 14.4641 133.094 16.4001C133.078 18.3041 132.47 19.7121 131.222 20.8481Z" fill="currentColor" />
            <path d="M157.206 20.0162L153.382 6.70418H148.662L144.854 19.9842L141.014 6.70418H136.31L142.486 26.2081H147.222L151.03 12.9762L154.822 26.2081H159.606L165.734 6.70418H161.03L157.206 20.0162Z" fill="currentColor" />
            <path d="M48.054 11.7761H44.806L43.366 14.5601L44.966 17.7281L48.054 11.7761Z" fill="currentColor" />
            <path d="M41.718 24.0321L40.086 20.8961L35.43 29.9201H38.678L41.718 24.0321Z" fill="currentColor" />
            <path d="M34.502 22.4001H31.27L24.39 35.7921H27.558L34.502 22.4001Z" fill="currentColor" />
            <path d="M36.438 20.2242L25.206 41.9201H28.438L39.654 20.2242H36.438Z" fill="currentColor" />
            <path d="M47.318 14.9442L35.686 37.3601H38.918L50.518 14.9442H47.318Z" fill="currentColor" />
            <path d="M36.102 11.5362L41.622 22.1601L42.166 23.2321L44.55 18.6242L38.358 6.70418H33.798L23.686 26.2081H28.502L36.102 11.5362Z" fill="currentColor" />
            <path d="M45.542 26.2081H48.486L47.014 23.3761L45.542 26.2081Z" fill="currentColor" />
            <path d="M85.814 21.8082C84.918 22.3041 83.526 22.5921 81.686 22.5921C78.854 22.5921 76.23 21.7762 73.766 20.1122L73.542 19.9682L71.19 23.2321L71.398 23.4081C73.894 25.5841 77.35 26.6401 81.67 26.6241C84.646 26.6241 87.014 26.0961 88.726 24.9281C90.454 23.7761 91.366 22.0001 91.35 19.8882C91.366 18.2402 90.774 16.8162 89.622 15.8722C88.47 14.9282 86.838 14.3522 84.694 14.0482C83.926 13.9362 82.422 13.8082 80.134 13.6482C78.614 13.5522 77.542 13.3602 76.998 13.1522C76.198 12.8002 76.038 12.5442 76.022 12.0642C76.038 11.6002 76.214 11.2962 76.966 10.8962C77.702 10.5282 78.902 10.3202 80.518 10.3202C82.918 10.3202 85.286 10.9602 87.638 12.2722L88.15 12.5602L88.534 12.0962L90.662 9.48818L90.07 9.04018C87.542 7.15218 84.39 6.22418 80.726 6.22418C78.006 6.22418 75.814 6.72018 74.198 7.82418C72.582 8.89618 71.702 10.5282 71.718 12.4322C71.702 14.2562 72.486 15.7762 73.99 16.5762C75.27 17.2642 77.222 17.6482 79.99 17.8562C83.03 18.0642 84.95 18.2882 85.494 18.4482C86.646 18.8322 86.934 19.2322 86.982 20.0642C86.95 20.8322 86.678 21.2962 85.814 21.8082Z" fill="currentColor" />
        </svg>

    );
};

export default NabSvg;
