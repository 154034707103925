import React from 'react';

const VoiceoverSvg = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_32_29186)">
                <path d="M15 32H7C3.134 32 0 35.134 0 39V41H22V39C22 35.134 18.866 32 15 32Z" fill="url(#paint0_linear_32_29186)" />
                <path d="M13 29H5V21C5 18.791 6.791 17 9 17H17V25C17 27.209 15.209 29 13 29Z" fill="url(#paint1_linear_32_29186)" />
                <path d="M25 7C22.239 7 20 9.239 20 12V30L26 25H39C41.761 25 44 22.761 44 20V7H25Z" fill="url(#paint2_linear_32_29186)" />
            </g>
            <defs>
                <linearGradient id="paint0_linear_32_29186" x1="-0.161" y1="58.393" x2="20.942" y2="21.841" gradientUnits="userSpaceOnUse">
                    <stop offset="0.005" stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_32_29186" x1="-0.446" y1="42.826" x2="20.864" y2="5.916" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_32_29186" x1="8.36" y1="49.057" x2="58.929" y2="-21.655" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <clipPath id="clip0_32_29186">
                    <rect width="44" height="34" fill="white" transform="translate(0 7)" />
                </clipPath>
            </defs>
        </svg>


    );
};

export default VoiceoverSvg;
