import React from "react"
import PropTypes from "prop-types"

import Wrapper from "../layout/wrapper/wrapper"
import Accordion from "../../elements/accordion/accordion"

const Faqs = ({ items }) => {
  return (
    <Wrapper>
      <Accordion items={items} />
    </Wrapper>
  )
}

Faqs.propTypes = {
  items: PropTypes.array,
}

Faqs.defaultProps = {
  items: [],
}

export default Faqs
