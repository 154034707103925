import React from "react"
import LogoWall from "../../components/logo-wall/logo-wall"
import { getPageUrl } from "../../utils/helpers"

export default ({ slice }) => {
  return (
    <LogoWall
      items={slice.fields}
      ctaText={slice?.primary?.cta_text}
      ctaLink={
        slice?.primary?.cta_link ? getPageUrl(slice?.primary?.cta_link) : null
      }
    />
  )
}
