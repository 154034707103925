import React, { useState } from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./accordion.module.scss"

const AccordionItem = ({ item }) => {
  const [isOpen, setIsOpen] = useState(false)
  return (
    <div className={isOpen ? Styles.accordionItemOpened : Styles.accordionItem}>
      <div
        className={Styles.accordionItem__line}
        onClick={() => {
          setIsOpen(!isOpen)
        }}
      >
        <RichText
          render={item.question}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />

        <span className={Styles.accordionItem__icon} />
      </div>
      <div className={Styles.accordionItem__inner}>
        <div className={Styles.accordionItem__content}>
          <RichText
            render={item.answer}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializer}
          />
        </div>
      </div>
    </div>
  )
}

AccordionItem.propTypes = {
  item: PropTypes.object,
}

AccordionItem.defaultProps = {
  items: {},
}

export default AccordionItem
