import React from 'react';

const ScaleSvg = () => {
    return (
        <svg width="38" height="38" viewBox="0 0 38 38" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.26001 9.91L11.16 15.82L15.82 11.16L9.92001 5.25L5.26001 9.91Z" fill="url(#paint0_linear_315_1126)" />
            <path d="M0 0L14 1.167L1.167 14L0 0Z" fill="url(#paint1_linear_315_1126)" />
            <path d="M9.91 32.7402L15.82 26.8402L11.16 22.1802L5.25 28.0802L9.91 32.7402Z" fill="url(#paint2_linear_315_1126)" />
            <path d="M0 38L1.167 24L14 36.833L0 38Z" fill="url(#paint3_linear_315_1126)" />
            <path d="M32.74 28.0902L26.84 22.1802L22.18 26.8402L28.08 32.7502L32.74 28.0902Z" fill="url(#paint4_linear_315_1126)" />
            <path d="M38 38L24 36.833L36.833 24L38 38Z" fill="url(#paint5_linear_315_1126)" />
            <path d="M28.09 5.25977L22.18 11.1598L26.84 15.8198L32.75 9.91976L28.09 5.25977Z" fill="url(#paint6_linear_315_1126)" />
            <path d="M38 0L36.833 14L24 1.167L38 0Z" fill="url(#paint7_linear_315_1126)" />
            <defs>
                <linearGradient id="paint0_linear_315_1126" x1="10.5312" y1="-1.77865" x2="10.5317" y2="29.0017" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint1_linear_315_1126" x1="-7.3154" y1="-13.6534" x2="13.9153" y2="16.6655" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint2_linear_315_1126" x1="-1.85876" y1="27.2934" x2="28.9216" y2="27.2939" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint3_linear_315_1126" x1="-13.8237" y1="45.1347" x2="16.496" y2="23.9051" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint4_linear_315_1126" x1="27.3768" y1="39.6621" x2="27.3763" y2="8.88176" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint5_linear_315_1126" x1="45.1556" y1="51.5502" x2="23.9249" y2="21.2313" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint6_linear_315_1126" x1="39.8436" y1="10.5603" x2="9.06327" y2="10.5598" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
                <linearGradient id="paint7_linear_315_1126" x1="51.7451" y1="-7.30831" x2="21.4254" y2="13.9213" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0" />
                    <stop offset="1" stop-color="white" stop-opacity="0.8" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default ScaleSvg;