import React from "react"
import PropTypes from "prop-types"
import Styles from "./checklist-item.module.scss"

const ChecklistItem = ({ title }) => {
  return (
    <p className={Styles.checklistItem}>
      <svg
        className={Styles.checklistItem__icon}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 22 16"
      >
        <path
          fill="#FFF"
          d="M21.491 1.644l-14.4 14.4L.509 9.462l1.688-1.687 4.894 4.894L19.803-.044z"
        />
      </svg>
      <span className={Styles.checklistItem__text}>{title}</span>
    </p>
  )
}

ChecklistItem.propTypes = {
  title: PropTypes.string,
}

ChecklistItem.defaultProps = {
  title: "",
}

export default ChecklistItem
