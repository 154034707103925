import React from 'react';

const Enterprise = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M24 2L6 8V25C6 38 24 46 24 46C24 46 42 38 42 25V8L24 2Z" fill="url(#paint0_linear_43_279)" />
            <path d="M30 19C30 15.686 27.314 13 24 13C20.686 13 18 15.686 18 19C18 21.219 19.208 23.153 21 24.191V33H23C25.209 33 27 31.209 27 29V24.191C28.792 23.153 30 21.219 30 19Z" fill="url(#paint1_linear_43_279)" />
            <defs>
                <linearGradient id="paint0_linear_43_279" x1="-1.603" y1="49.844" x2="55.906" y2="-18.692" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_43_279" x1="0.318" y1="-0.424" x2="38.922" y2="38.18" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default Enterprise;
