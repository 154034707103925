import React from "react"
import Styles from "./static-page-max-width-wrapper.module.scss"

const StaticPageMaxWithWrapper = ({ children, ...props }) => {
  return (
    <div className={Styles.wrapper} {...props}>
      {children}
    </div>
  )
}


export default StaticPageMaxWithWrapper
