import React from "react"
import Styles from "./static-section-heading.module.scss"

const StaticSectionHeading = ({ children, ...props }) => {
  return (
    <section className={Styles.heading} {...props}>
      {children}
    </section>
  )
}


export default StaticSectionHeading
