import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./overview.module.scss"
import Title from "../../elements/title/title"
import ButtonGroup from "../button-group/button-group"
import Button from "../button/button"

const Overview = ({
  theme,
  headline,
  summary,
  buttons,
  isHero,
  link,
  linkText,
}) => {
  return (
    <div
      className={cn(
        Styles.Overview,
        theme === "Light" ? Styles.OverviewLight : Styles.OverviewDark,
        isHero && Styles.OverviewHero
      )}
    >
      <div className={Styles.Overview__container}>
        <Title tag="h2" title={headline} />
        {summary && (
          <RichText
            render={summary}
            linkResolver={linkResolver}
            htmlSerializer={htmlSerializer}
          />
        )}

        {link && linkText && (
          <div className={Styles.Overview__link}>
            <Button link={link}>{linkText}</Button>
          </div>
        )}

        <ButtonGroup buttons={buttons} />
      </div>
    </div>
  )
}

Overview.propTypes = {
  theme: PropTypes.string,
  headline: PropTypes.string,
  summary: PropTypes.array,
  buttons: PropTypes.array,
  isHero: PropTypes.bool,
  link: PropTypes.string,
  linkText: PropTypes.string,
}

Overview.defaultProps = {
  theme: "Dark",
  headline: "Headline",
  summary: [],
  buttons: [],
  isHero: false,
}

export default Overview
