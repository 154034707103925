import React, { useState, useEffect } from "react"
import Styles from "./snackbar.module.scss"
import cn from "classnames"

const GetLink = (link, setVisible) => {
  if (link && link._linkType !== "Link.web") return null

  return (
    <>
      -
      <a
        onClick={() => {
          setVisible(false)
        }}
        href={link.url}
        target="_blank"
        className={Styles.snackbar__link}
      >
        Read more
      </a>
    </>
  )
}

const Snackbar = ({ readMoreLink, messaging }) => {
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setVisible(true)
    }, 1000)
  }, [])

  if (!readMoreLink || !messaging) return null

  return (
    <div className={cn([Styles.snackbar, visible && Styles.snackbarVisible])}>
      {<p className={Styles.snackbar__messaging}>{messaging}</p>}{" "}
      {GetLink(readMoreLink, setVisible)}
      <div className={Styles.snackbar__greenBox} />
      <div className={Styles.snackbar__redBox} />
    </div>
  )
}

export default Snackbar
