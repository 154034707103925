import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

import Styles from "./link-arrow.module.scss"

const LinkArrow = ({ children, ...props }) => {
  return (
    <Link className={Styles.LinkArrow} {...props}>
      {children}
      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 9.75H14.75M10.25 4.5L15.5 9.75L10.25 15" stroke="currentColor" strokeWidth="2" strokeLinecap="square" />
      </svg>
    </Link>
  )
}


export default LinkArrow
