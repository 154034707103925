import React from "react"
import InlineUpgradeForm from "../../components/inline-upgrade-form"

export default ({ slice }) => {
  const {
    headline,
    description,
    what_youllget_heading,
    collaboration_heading,
    book_a_demo_heading,
    book_a_demo_description,
    netlify_form_id,
  } = slice.primary

  return (
    <InlineUpgradeForm
      netlifyFormId={netlify_form_id}
      headline={headline}
      description={description}
      collaborationHeading={collaboration_heading}
      whatYoullGetHeading={what_youllget_heading}
      bookADemoHeading={book_a_demo_heading}
      bookADemoDescription={book_a_demo_description}
      benefitList={slice.fields}
    />
  )
}
