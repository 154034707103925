import React from "react"
import PropTypes from "prop-types"
import Styles from "./contact.module.scss"
import Wrapper from "../layout/wrapper/wrapper"
import { SplitContainer, SplitLeft, SplitRight } from "../layout/split/split"
import { RichText } from "prismic-reactjs"
import SignUpForm from "../sign-up-form/sign-up-form"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Map from "../map/map"

const Contact = ({ contactText }) => {
  return (
    <>
      <Wrapper>
        <SplitContainer>
          <SplitLeft>
            <div className={Styles.contactTextContainer}>
              <RichText
                render={contactText}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </SplitLeft>
          <SplitRight type={"contact"}>
            <div className={Styles.formContainer}>
              <SignUpForm showTextArea formName={"contact-us-request"} />
            </div>
          </SplitRight>
        </SplitContainer>
      </Wrapper>
      <Map />
    </>
  )
}

Contact.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  whatYoullGetHeading: PropTypes.string,
  collaborationHeading: PropTypes.string,
  benefitList: PropTypes.array,
}

export default Contact
