import React from "react"
import PropTypes from "prop-types"
import Wrapper from "../layout/wrapper/wrapper"
import { SplitContainer, SplitLeft, SplitRight } from "../layout/split/split"
import Title from "../../elements/title/title"
import Text from "../../elements/text/text"
import BenefitList from "../../elements/plain-list/plain-list"
import SignUpForm from "./inline-upgrade-form"

const InlineUpgradeForm = props => {
  const {
    netlifyFormId,
    headline,
    description,
    whatYoullGetHeading,
    collaborationHeading,
    bookADemoHeading,
    bookADemoDescription,
    benefitList,
  } = props

  return (
    <Wrapper>
      <SplitContainer>
        <SplitLeft>
          <Title tag={"h1"} title={headline} color={"blue"} />
          <Text text={description} />
          <Title tag={"h2"} title={whatYoullGetHeading} />
          {benefitList && (
            <BenefitList modifierClass={"Demo"} listItems={benefitList} />
          )}
          <Title tag={"h2"} title={collaborationHeading} />
        </SplitLeft>
        <SplitRight>
          <div>
            <Title tag={"h2"} title={bookADemoHeading} />
            <Text text={bookADemoDescription} />
            <SignUpForm
              formAction={"/upgrade-confirmation"}
              formName={netlifyFormId}
            />
          </div>
        </SplitRight>
      </SplitContainer>
    </Wrapper>
  )
}

InlineUpgradeForm.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  whatYoullGetHeading: PropTypes.string,
  collaborationHeading: PropTypes.string,
  benefitList: PropTypes.array,
}

export default InlineUpgradeForm
