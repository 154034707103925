import React, { useState } from "react"
import PropTypes from "prop-types"
import Title from "../../elements/title/title"
import Styles from "./steps.module.scss"
import Play from "../../images/play-button.svg"

const StepsNav = ({ items, onPlay, onSelect }) => {
  const [selected, changeSelected] = useState(0)

  const getSelection = key => {
    onSelect(key)
    changeSelected(key)
  }

  const content = items.map((item, key) => {
    return (
      <li
        key={key}
        className={`${Styles.steps__item} ${
          selected === key ? Styles.steps__itemSelected : ""
        }`}
        onClick={() => getSelection(key)}
      >
        <div className={Styles.steps__numberWrapper}>
          <span className={Styles.steps__number}>0{key + 1}</span>
        </div>

        <div className={Styles.steps__contentWrapper}>
          <Title tag="h3" title={item.headline} />

          <div className={Styles.steps__content}>
            <p className={Styles.steps__summary}>{item.summary}</p>
            {item.url && (
              <p
                className={Styles.steps__link}
                onClick={() => onPlay(item.url)}
              >
                See how it works
                <img
                  className={Styles.steps__playButton}
                  src={Play}
                  alt={item.headline}
                />
              </p>
            )}
          </div>
        </div>
      </li>
    )
  })

  return <ul className={Styles.steps__nav}>{content}</ul>
}

StepsNav.propTypes = {
  items: PropTypes.array,
  onPlay: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
}

StepsNav.defaultProps = {
  items: [],
}

export default StepsNav
