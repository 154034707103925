import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./hero-grid.module.scss"
import Lockup from "../lockup/lockup"
import ButtonGroup from "../button-group/button-group"
import { StaticImage } from "gatsby-plugin-image"

const HeroGrid = ({ headline, summary, buttons, image1Sharp, image2Sharp, image3Sharp, image4Sharp, image5Sharp, image6Sharp, ...rest }) => {
  return (
    <div className={Styles.HeroGrid}>
      <div className={Styles.HeroGrid__container}>
        <Lockup
          tag="h1"
          title={headline ? headline : ""}
          text={
            summary
              ? RichText.asText(summary, linkResolver, htmlSerializer)
              : ""
          }
        />
        {buttons && <ButtonGroup theme="Light" buttons={buttons} />}
      </div>
      {/* 
      <div className={Styles.grid}>
        <div className={Styles.half}>
          <div className={Styles.twoThirds}>
            <StaticImage src="./live-event.jpg" alt="Live event" loading="eager" placeholder="none" quality={100} />
          </div>
          <div className={`${Styles.third} ${Styles.column}`}>
            <StaticImage src="./analytics.jpg" alt="Analytics" loading="eager" placeholder="none" quality={100} />
            <StaticImage src="./tablet.jpg" alt="Tablet" loading="eager" placeholder="none" quality={100} />
          </div>
        </div>
        <div className={`${Styles.half} ${Styles.column}`}>
          <div className={Styles.trainingContainer}>
            <StaticImage src="./training.jpg" alt="Training" quality={100} loading="eager" />
          </div>
          <div className={Styles.waveContainer}>
            <StaticImage src="./wave-menu.png" alt="Wave" loading="eager" placeholder="none" quality={100} />
          </div>
        </div>
      </div> */}

      <div className={Styles.gridNew}>
        <div>
          <StaticImage src="./supercell.png" alt="Wave" loading="eager" placeholder="none" quality={100} />
        </div>
        <div>
          <StaticImage src="./yoga.png" alt="Wave" loading="eager" placeholder="none" quality={100} />
        </div>
        <div>
          <StaticImage src="./wave.png" alt="Wave" loading="eager" placeholder="none" quality={100} />
        </div>
      </div>
    </div >
  )
}

HeroGrid.propTypes = {
  headline: PropTypes.string,
  summary: PropTypes.array,
  buttons: PropTypes.array,
}

HeroGrid.defaultProps = {
  headline: "Headline",
  summary: [],
  buttons: [],
}

export default HeroGrid
