import React from 'react';

const SupercellSvg = () => {
    return (
        <svg width="61" height="48" viewBox="0 0 61 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_537_87)">
                <path d="M31.6456 20.2419H28.1955V22.7721H31.6456V20.2419ZM33.315 31.5349L31.6456 29.8605V25.1163H28.1955V27.6465H29.4198V31.5535H20.5163V27.6465H22.1857V20.2419H20.5163V16.3349H35.4296L37.9152 18.8651V22.0279L35.6893 23.6651L37.9152 25.3395V28.186H39.3249V31.5349H33.315ZM37.9523 3.90698V11.8512L34.6135 15.2H25.5245L22.1857 11.8512V3.90698H20.5163V0H29.6794V3.90698H28.2326V11.3116H33.3336V3.90698H32.0908V0H39.362V3.90698H37.9337M53.3293 3.90698H48.2283V6.51163H53.3478V3.90698H53.3293ZM56.9463 9.17209H48.2283V11.2744H49.8421V15.1814H40.5306V11.2744H42.2V3.90698H40.5306V0H56.6681L59.3577 2.60465V6.77209L56.9463 9.15349M55.9447 31.5349H44.4815L40.5306 27.6279V20.2233L44.4815 16.3163H53.5148L54.6277 17.7488L55.7406 16.3349H59.3577V22.7349H54.6277V20.2233H46.6888V27.6651H54.6277V25.1163H59.3577V28.186L55.9447 31.5349ZM37.6926 47.9442H20.5163V44.0372H22.1857V36.6512H20.5163V32.7442H29.8649V36.6512H28.1955V44.0558H34.6506V39.1442H39.362V46.1395L37.6926 47.9442ZM57.6883 47.9442H40.512V44.0372H42.1443V36.6512H40.512V32.7442H49.8235V36.6512H48.1541V44.0558H54.6463V39.1442H59.3577V46.1395L57.6883 47.9442ZM16.4355 15.2H5.93686L4.69408 13.8605L3.58115 15.2H0.557678V10.5488H4.78683V11.8512H12.7072V9.67442H3.28437L0.557678 7.12558V3.40465L3.71099 0H13.1338L14.3766 1.33953L15.4895 0H18.513V4.65116H14.2468V3.34884H7.12399V5.54419H16.3242L19.4034 8.46512V12L16.4355 15.2ZM17.6969 31.5349H0.557678V27.6279H2.22708V20.2791H0.557678V16.3721H17.6969L19.3663 18.2326V23.4419L14.6548 23.4791V20.2791H8.23692V22.5116H11.0193V25.3395H8.23692V27.6465H14.6548V24.4837H19.3663V29.693L17.6969 31.5535M17.6969 47.9256H0.557678V44.0186H2.22708V36.6512H0.557678V32.7442H17.6969L19.3663 34.6047V39.814H14.6548V36.6512H8.23692V38.9395H11.0193V41.7302H8.23692V44.093H14.6548V40.9302H19.3663V46.1395L17.6969 48" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_537_87">
                    <rect width="59.4" height="48" fill="currentColor" transform="translate(0.299988)" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default SupercellSvg;
