import React from "react"
import cn from "classnames"
import StaticPageMaxWithWrapper from "../static-page-max-width-wrapper/static-page-max-width-wrapper"
import Styles from "./static-section-clients.module.scss"
import LinkArrow from "../link-arrow/link-arrow"
import MastercardSvg from "./mastercard-svg"
import TedSvg from "./ted-svg"
import AllianzSvg from "./allianz-svg"
import PrevSvg from "./prev-svg"
import NextSvg from "./next-svg"
import authorProfile from "../../images/section-clients/people/author-profile.png"
import gsap from "gsap"
import tedBg from "./ted-bg.png"
import supercellBg from "./supercell-bg.png"
import nabBg from "./nab-bg.png"
import miisBg from "./miis-bg.png"
import allianzBg from "./allianz-bg.png"
import loblawBg from "./loblaw-bg.png"
import LoblawsSvg from "./loblaw-svg"
import NabSvg from "./nabshow-svg"
import MiisSvg from "./miis-svg"
import SupercellSvg from "./supercell-svg"

const items = [
  {
    title: "Ted",
    description: '"CaptionHub provides our volunteers with a platform to create high- quality subtitles quickly.Now, our global audiences can access big ideas faster than ever before."',
    url: "/clients/ted",
    img: "https://via.placeholder.com/600x400",
    logo: <TedSvg />,
    name: 'Jenny Zurawell',
    JobTitle: 'Director of Localisation',
    profilePic: authorProfile,
    bg: tedBg,
    stats: [
      { figure: "38,946", description: "TED translators using CaptionHub" },
      { figure: "186,431", description: "Translations to date" },
    ]
  },
  {
    title: "Supercell",
    description: '"Subtitling non-scripted videos is a complex task, and in our fast-paced environment, every minute of time saved counts. Auto-transcription, timing, and integration with our translation tool are all features that save on the management side. Time we now get to spend on the translation of our videos."',
    url: "/clients/supercell",
    img: "https://via.placeholder.com/600x400",
    logo: <SupercellSvg />,
    name: 'Silvio Clausen',
    JobTitle: 'Head of Localisation, Supercell',
    profilePic: authorProfile,
    bg: supercellBg,
    stats: [
      { figure: "758,000", description: "subscribers" },
      { figure: "33M+", description: "views" },
    ]
  },
  {
    title: "Nab",
    description: '"CaptionHub was selected for its ability to deliver subtitles at the scale needed by NAB."',
    url: "/clients/nab",
    img: "https://via.placeholder.com/600x400",
    logo: <NabSvg />,
    name: 'Dorian Sullivan',
    JobTitle: 'Vice President, Audience Development',
    profilePic: authorProfile,
    bg: nabBg,
    stats: [
      { figure: "40,000", description: "online delegates consumed automated transcription and captioning" },
      { figure: "150+", description: "Countries" },
    ]
  },
  {
    title: "MIIS",
    description: '"We always tell our students that among the traits better, faster, and cheaper, they can only pick two. With CaptionHub, I can show students how we can do things more efficiently, lower prices, and theoretically increase quality as well."',
    url: "/clients/middlebury-institute-of-international-studies",
    img: "https://via.placeholder.com/600x400",
    logo: <MiisSvg />,
    name: 'Max Troyer',
    JobTitle: 'Professor, The Middlebury Institute of International Studies (MIIS)',
    profilePic: authorProfile,
    bg: miisBg,
    stats: [
      { figure: "1000+", description: "Students enrolled in the Translation and Localization Management program" },
    ]
  },
  {
    title: "Loblaw",
    description: '"To be able to collaborate with our translation partners in Quebec on the same project, that whole workflow improvement has been the biggest game changer. It saves us time. It saves us money.It does all the things that you want a new product to do."',
    url: "https://prismic-io.s3.amazonaws.com/captionhub/ec5f98f9-257e-4d45-8c85-95adcb750a6c_Case+study_Loblaw_2022.pdf",
    img: "https://via.placeholder.com/600x400",
    logo: <LoblawsSvg />,
    name: 'Andrew Fischer',
    JobTitle: 'Executive Producer, Loblaw',
    profilePic: authorProfile,
    bg: loblawBg,
    stats: [
      {
        figure: "12 Million", description: "customers engaged with high-quality subtitles"
      },
      { figure: "200,000", description: "employees communicated to in French and English for compliance with the Accessible Canada Act" }
    ]
  },
  {
    title: "Allianz",
    description: 'I am producing the captions in an 1/8th of the time I did before. It is very intuitive and the accuracy of the speech recognition is very impressive, adding a new dimension of professionalism our videos."',
    url: "/clients/allianz",
    img: "https://via.placeholder.com/600x400",
    logo: <AllianzSvg />,
    name: 'Steve Flynn',
    JobTitle: 'Global Marketing Manager, Allianz',
    profilePic: authorProfile,
    bg: allianzBg,
    stats: [
      { figure: "186,431", description: "Translations to date" },
      { figure: "800%", description: " increase in transcription and translation speed" },
    ]
  },
]

const StaticSectionClients = ({ children, ...props }) => {
  const [activeIndex, setActiveIndex] = React.useState(0)
  const activeItem = items[activeIndex]

  React.useEffect(() => {
    gsap.fromTo(
      `.gsap-fade`,
      { opacity: 0, },
      { opacity: 1, duration: 1 }
    );
  }, [activeIndex]);

  return (
    <section className={Styles.clients} {...props}>

      <StaticPageMaxWithWrapper>
        <div className={Styles.gradient}>
          <div className={Styles.bg}>
            <div className={Styles.headingContainer}>
              <h2 className={Styles.heading}>Learn how our clients have slashed turnaround times, saved money and improved their brand communication</h2>
              <div className={Styles.subCopy}>
                <p>Join other global enterprise brands using CaptionHub to localise their video and audio, faster, more accurately and more securely than previously possible.</p>
                <LinkArrow href="/clients">See all customer stories</LinkArrow>
              </div>
            </div>
            <div className={Styles.carouselControls}>
              <button disabled={activeIndex === 0} onClick={() => { setActiveIndex(activeIndex - 1) }}><PrevSvg /></button>
              <button disabled={activeIndex === items.length - 1} onClick={() => { setActiveIndex(activeIndex + 1) }} ><NextSvg /></button>
            </div>

            <div className={Styles.carouselContent}>
              <div className={Styles.carouselContent__copy}>
                <div className={'gsap-fade'}>
                  <div className={Styles.carouselContent__logo}>{activeItem.logo}</div>
                  <h3 className={Styles.carouselContent__desc}>{activeItem.description}</h3>
                  <div className={Styles.carouselContent__profile}>
                    {/* <img src={activeItem.profilePic} alt={activeItem.name} /> */}
                    <div>
                      <p>{activeItem.name}</p>
                      <p>{activeItem.JobTitle}</p>
                    </div>
                  </div>
                  <LinkArrow href={activeItem.url}>Read success story</LinkArrow>
                </div>
              </div>

              <div className={Styles.carouselContent__stats} >
                <div className={cn([Styles.carouselContent__bg, 'gsap-fade'])} style={{ backgroundImage: `url(${activeItem.bg})` }}></div>
                <div className={Styles.carouselContent__statsContainer}>
                  {activeItem.stats.map((stat, index) => (
                    <div className={cn([Styles.carouselContent__statsItem, 'gsap-fade'])}>
                      <p>{stat.figure}</p>
                      <p>{stat.description}</p>
                    </div>
                  ))}
                </div>
              </div>
            </div>

            <div className={Styles.itemLogos}>
              {items.map((item, index) => (<div onClick={() => setActiveIndex(index)} className={cn([Styles.itemLogo, index === activeIndex && Styles.itemLogoActive])}>{item.logo}</div>))}
            </div>
          </div>
        </div>


      </StaticPageMaxWithWrapper >

    </section >
  )
}


export default StaticSectionClients
