import React from "react"
import Checklist from "../../components/checklist/checklist"

export default ({ slice }) => {
  const { headline, button_title, button_link } = slice.primary
  return (
    <Checklist
      headline={headline}
      buttonTitle={button_title}
      buttonLink={button_link}
      items={slice.fields}
    />
  )
}
