import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import cn from "classnames"

import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./text-summary-with-quote.module.scss"
import Lockup from "../lockup/lockup"

const textSummaryWithQuote = ({
  headline,
  summary,
  authorPosition,
  quote = null,
  quoteAuthor = null,
  summaryAlignment,
}) => {
  const hasQuote = quote && quoteAuthor
  return (
    <div className={Styles.textSummaryWithQuote}>
      <div
        className={cn(
          Styles.textSummaryWithQuote__container,
          summaryAlignment === "right" &&
            Styles.textSummaryWithQuote__containerRightAligned
        )}
      >
        <div
          className={cn(
            Styles.textSummaryWithQuote__summaryWrapper,
            !hasQuote && Styles.textSummaryWithQuote__summaryWrapperFullWidth
          )}
        >
          <div className={Styles.textSummaryWithQuote__summary}>
            <Lockup
              tag="h2"
              title={headline ? headline : ""}
              text={
                summary
                  ? RichText.asText(summary, linkResolver, htmlSerializer)
                  : ""
              }
            />
          </div>
        </div>

        {hasQuote && (
          <div className={Styles.textSummaryWithQuote__quoteWrapper}>
            <h3 className={Styles.textSummaryWithQuote__quote}>{quote}</h3>
            <h4 className={Styles.textSummaryWithQuote__quoteAuthor}>
              {quoteAuthor}
            </h4>
            {authorPosition && (
              <h4 className={Styles.textSummaryWithQuote__authorPosition}>
                {authorPosition}
              </h4>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

textSummaryWithQuote.propTypes = {
  headline: PropTypes.string,
  summary: PropTypes.array,
  summaryAlignment: PropTypes.oneOf(["left", "right"]),
  quoteAuthor: PropTypes.string,
  quote: PropTypes.string,
  authorPosition: PropTypes.string,
}

textSummaryWithQuote.defaultProps = {
  headline: "",
  summary: [],
  summaryAlignment: "left",
  quoteAuthor: null,
  quote: null,
  authorPosition: null,
}

export default textSummaryWithQuote
