import React from "react"
import PropTypes from "prop-types"
import Text from "../text/text"
import Styles from "./plain-list.module.scss"

const PlainList = ({ listItems, modifierClass }) => {
  return (
    <ul className={Styles[`plainList${modifierClass}`]}>
      {listItems.map(listItem => {
        return (
          <li className={Styles.plainListItem} key={listItem.list_item}>
            <Text text={listItem.list_item} />
          </li>
        )
      })}
    </ul>
  )
}

PlainList.propTypes = {
  listItems: PropTypes.array,
}

PlainList.defaultProps = {
  listItems: [],
  modifier: "",
}

export default PlainList
