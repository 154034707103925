import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { RichText } from "prismic-reactjs"

import Styles from "./pricing.module.scss"
import Title from "../../elements/title/title"
import ButtonGroup from "../button-group/button-group"

const Pricing = ({
  panel1ButtonPageLink,
  panel1ButtonText,
  panel1Heading,
  panel1List,
  panel1Subheading,
  panel2ButtonPageLink,
  panel2ButtonText,
  panel2Heading,
  panel2List,
  panel2Subheading,
  ...rest
}) => {
  return (
    <div className={cn(Styles.pricing)}>
      <div className={cn(Styles.pricing__panel1)}>
        <Title
          tag="h2"
          title={panel1Heading}
          className={cn(Styles.pricing__heading)}
        />
        <Title tag="h3" title={panel1Subheading} />
        <div className={Styles.pricing__richText}>
          <RichText render={panel1List} />
        </div>
        <ButtonGroup
          buttons={[{ title: panel1ButtonText, link: panel1ButtonPageLink }]}
          theme={"Light"}
        />
      </div>
      <div className={cn(Styles.pricing__panel2)}>
        <Title tag="h2" title={panel2Heading} />
        <Title tag="h3" title={panel2Subheading} />
        <div className={Styles.pricing__richText}>
          <RichText render={panel2List} />
        </div>
        <ButtonGroup
          buttons={[{ title: panel2ButtonText, link: panel2ButtonPageLink }]}
          theme={"Light"}
        />
      </div>
    </div>
  )
}

Pricing.propTypes = {
  headline: PropTypes.string,
  region: PropTypes.string,
  industry: PropTypes.string,
  summary: PropTypes.array,
}

Pricing.defaultProps = {
  headline: "",
  industry: null,
  region: null,
  summary: null,
}

export default Pricing
