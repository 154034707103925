import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./demo.module.scss"
import Title from "../../elements/title/title"

const Demo = ({ headline, summary }) => {
  return (
    <div className={cn(Styles.Demo)}>
      <div className={Styles.Demo__container}>
        <Title tag="h2" title={headline} />
        <RichText
          render={summary}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />
        <div className={Styles.Demo__form}>
          <iframe
            src="https://tombridges.typeform.com/to/piSz3E?typeform-embed=popup-blank&amp;embed-hide-footer=true&amp;embed-hide-headers=true&amp;typeform-embed-id=cyenp"
            data-qa="iframe"
            frameBorder="0"
            width="100%"
            height="500"
            title="CaptionHub Demo"
          ></iframe>
        </div>
      </div>
    </div>
  )
}

Demo.propTypes = {
  headline: PropTypes.string,
  summary: PropTypes.array,
}

Demo.defaultProps = {
  headline: "Headline",
  summary: [],
}

export default Demo
