import React from 'react';

const TickSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="url(#paint0_linear_269_84)" />
            <path d="M13.172 26.364L26.607 12.929C27.779 11.757 29.678 11.757 30.85 12.929L32.557 14.636L19.121 28.071C17.949 29.243 16.05 29.243 14.878 28.071L13.172 26.364Z" fill="url(#paint1_linear_269_84)" />
            <path d="M7.10101 20.293L13.172 26.364L17 22.536L13.05 18.586C11.878 17.414 9.97901 17.414 8.80701 18.586L7.10101 20.293Z" fill="url(#paint2_linear_269_84)" />
            <defs>
                <linearGradient id="paint0_linear_269_84" x1="-2.044" y1="-2.044" x2="50.828" y2="50.828" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_269_84" x1="6.5" y1="20.5002" x2="40.6256" y2="20.5036" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_269_84" x1="5.03901" y1="13.125" x2="18.6551" y2="29.3502" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default TickSvg;