import React from 'react';

const UnifiedPlatform = () => {
    return (
        <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M35 32H0V5C0 2.239 2.239 0 5 0H40V27C40 29.761 37.761 32 35 32Z" fill="url(#paint0_linear_44_3100)" />
            <path d="M34 24V23H9C7.343 23 6 24.343 6 26V27H31C32.657 27 34 25.657 34 24ZM25.5 14H34V15.5C34 16.881 32.881 18 31.5 18H23V16.5C23 15.119 24.119 14 25.5 14ZM25.5 7H34V8.5C34 9.881 32.881 11 31.5 11H23V9.5C23 8.119 24.119 7 25.5 7Z" fill="url(#paint1_linear_44_3100)" />
            <path d="M19.99 12.5H11.5V4.01001C16.08 4.27001 19.73 7.92001 19.99 12.5Z" fill="url(#paint2_linear_44_3100)" />
            <path d="M18 12.5C18 16.09 15.09 19 11.5 19C7.91 19 5 16.09 5 12.5C5 8.91 7.91 6 11.5 6V12.5H18Z" fill="url(#paint3_linear_44_3100)" />
            <defs>
                <linearGradient id="paint0_linear_44_3100" x1="-2.484" y1="-22.944" x2="54.517" y2="75.786" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_44_3100" x1="5.985" y1="-7.27499" x2="36.282" y2="45.201" gradientUnits="userSpaceOnUse">
                    <stop offset="0.005" stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_44_3100" x1="1.67001" y1="-12.846" x2="23.01" y2="24.116" gradientUnits="userSpaceOnUse">
                    <stop offset="0.005" stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_44_3100" x1="26.467" y1="15.19" x2="-16.396" y2="7.632" gradientUnits="userSpaceOnUse">
                    <stop offset="0.005" stop-color="white" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default UnifiedPlatform;
