import React from "react"
import PropTypes from "prop-types"
import ResultCard from "./result-card"
import Styles from "./results.module.scss"
import Wrapper from "../layout/wrapper/wrapper"

const Results = props => {
  const content = props.items.map((item, key) => {
    return (
      <ResultCard
        key={key.toString()}
        headline={item.headline}
        description={item.description}
        image={item.imageSharp}
        index={key}
      />
    )
  })

  return (
    <Wrapper>
      <ul className={Styles.results}>{content}</ul>
    </Wrapper>
  )
}

Results.propTypes = {
  items: PropTypes.array,
}

Results.defaultProps = {
  items: [],
}

export default Results
