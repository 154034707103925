import React, { useState } from "react"
import PropTypes from "prop-types"
import StepsNav from "./steps-nav"
import Image from "../../elements/image/image"
import Overlay from "../../components/overlay/overlay"
import Video from "../../components/video/video"
import Styles from "./steps.module.scss"

const Steps = ({ headline, items }) => {
  const links = []
  const images = []

  items.forEach((item, key) => {
    const payload = {
      headline: item.headline,
      summary: item.summary,
      url: item.url && item.url.embed_url,
      key,
    }

    links.push(payload)
    images.push({ sharp: item.imageSharp, image: item.image })
  })

  const [currentItem, changeItem] = useState(0)
  const [image, changeImage] = useState(images[0])
  const [videoUrl, changeUrl] = useState(links[0].url)
  const [overlay, triggerOverlay] = useState(false)
  const [fading, fadingImage] = useState(false)

  const loadVideo = () => {
    triggerOverlay(true)
    changeUrl(links[currentItem].url)
  }

  const selectImage = index => {
    fadingImage(true)
    changeItem(index)

    setTimeout(() => {
      changeImage(images[index])
      fadingImage(false)
    }, 400)
  }

  return (
    <div className={Styles.steps}>
      <h2 className={Styles.steps__headline}>{headline}</h2>
      <div className={Styles.steps__main}>
        <StepsNav items={links} onPlay={loadVideo} onSelect={selectImage} />

        {overlay && (
          <Overlay closeAction={() => triggerOverlay(false)}>
            <Video url={videoUrl} />
          </Overlay>
        )}

        <div
          className={`${Styles.steps__image} ${
            fading ? Styles.steps__imageHidden : ""
          }`}
          onClick={() => loadVideo(videoUrl)}
        >
          {image.sharp && image.sharp.childImageSharp && (
            <Image sharp={image.sharp} alt={headline} />
          )}
          {!image.sharp.childImageSharp && image.image && (
            <Image static src={image.image.url} alt={headline} />
          )}
        </div>
      </div>
    </div>
  )
}

Steps.propTypes = {
  headline: PropTypes.string,
  items: PropTypes.array,
}

Steps.defaultProps = {
  headline: "",
  items: [],
}

export default Steps
