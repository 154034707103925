import React from "react"
// import PropTypes from "prop-types"
import Styles from "./overlay.module.scss"

const Overlay = ({ children, ...props }) => {
  return (
    <div className={Styles.overlay}>
      <span className={Styles.overlay__close} onClick={props.closeAction}>
        <svg height="21" width="21">
          <path
            d="M11.607 10.192l8.485 8.486a1 1 0 0 1-1.414 1.414l-8.486-8.485-8.485 8.485a1 1 0 1 1-1.414-1.414l8.485-8.486L.293 1.707A1 1 0 1 1 1.707.293l8.485 8.485L18.678.293a1 1 0 0 1 1.414 1.414l-8.485 8.485z"
            fill="#000000"
            fillRule="evenodd"
          ></path>
        </svg>
      </span>
      {children}
    </div>
  )
}

// Overlay.propTypes = {
//   slices: PropTypes.array,
// }

// Overlay.defaultProps = {
//   slices: PropTypes.array,
// }

export default Overlay
