import React from "react"
import PropTypes from "prop-types"
import Styles from "./registration.module.scss"
import Wrapper from "../layout/wrapper/wrapper"
import { SplitContainer, SplitLeft, SplitRight } from "../layout/split/split"
import { RichText } from "prismic-reactjs"
import RegistrationForm from "../registration-form/registration-form"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"

const Registration = ({ contactText, netlifyFormId }) => {
  return (
    <>
      <Wrapper>
        <SplitContainer>
          <SplitLeft>
            <div className={Styles.registrationTextContainer}>
              <RichText
                render={contactText}
                linkResolver={linkResolver}
                htmlSerializer={htmlSerializer}
              />
            </div>
          </SplitLeft>
          <SplitRight type={"contact"}>
            <div className={Styles.formContainer}>
              <RegistrationForm formName={netlifyFormId} />
            </div>
          </SplitRight>
        </SplitContainer>
      </Wrapper>
    </>
  )
}

Registration.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  whatYoullGetHeading: PropTypes.string,
  collaborationHeading: PropTypes.string,
  benefitList: PropTypes.array,
}

export default Registration
