import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { getPageUrl } from "../../utils/helpers"
import Styles from "./sub-navigation.module.scss"

const SubNavigation = ({ ...props }) => {
  const items = props.items.map((item, key) => {
    return (
      <li key={key} className={`${Styles.subnavigation__item}`}>
        <Link
          className={`${Styles.subnavigation__link}`}
          activeClassName={Styles.subnavigation__linkSelected}
          to={getPageUrl(item.node)}
        >
          {item.node.title}
        </Link>
      </li>
    )
  })

  return (
    <nav className={Styles.subnavigation}>
      <ul className={`${Styles.subnavigation__list}`}>{items}</ul>
    </nav>
  )
}

SubNavigation.propTypes = {
  items: PropTypes.array,
}

SubNavigation.defaultProps = {
  items: [],
}

export default SubNavigation
