import React, { Component } from "react"
import Select from "react-select"
import { navigate } from "gatsby"
import omit from "lodash.omit"
import Cookies from "js-cookie"

import Styles from "./signup-form-simple.module.scss"

const selectOptions = [
  { value: "demo", label: "Demo" },
  { value: "trial", label: "Trial" },
  { value: "Pricing", label: "Pricing" },
]

const selectCustomStyles = {
  control: provided => ({
    ...provided,
    height: 50,
  }),
  placeholder: provided => ({
    color: "rgba(0, 0, 0, 0.3)",
  }),
}

class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.ContactForm = React.createRef()

    let campaignURL = Cookies.get("__gtm_campaign_url")
    let email = Cookies.get("email")
    let team_name = Cookies.get("team_name")
    this.state = {
      campaignURL: campaignURL,
      email: email,
      team_name: team_name,
    }
  }
  handleSelectChange = selectedOption => {
    this.setState({ selectedOption, action: selectedOption.value })
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.ContactForm.current
    const fieldsToSubmit = omit(this.state, "selectedOption")
    const { email } = this.state

    if (window.trackRoarEvent) {
      window.trackRoarEvent(email)
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        ...fieldsToSubmit,
      }),
    })
      .then(response => {
        navigate(form.getAttribute("action"))
      })
      .catch(error => {
        console.log(`error in submiting the form data:${error}`)
      })
  }
  render() {
    const { selectedOption, email, action, company } = this.state
    const { formName, formAction = "/contact-confirmation" } = this.props
    let canSubmit = (email && action && company) || false

    return (
      <form
        className={Styles.form}
        name={formName}
        method="post"
        action={formAction}
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={this.handleSubmit}
        ref={this.ContactForm}
      >
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label htmlFor={"bot-field"}>
            Don’t fill this out:{" "}
            <input
              name="bot-field"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div hidden>
          <input
            name="campaignURL"
            onChange={this.handleChange}
            value={this.state.campaignURL}
          />
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"email"}>
            Email address*
            <br />
            <input
              placeholder={"Please enter your email address"}
              required={true}
              type="email"
              name="email"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"company"}>
            Company name*
            <br />
            <input
              required
              placeholder={"Please enter your company name"}
              type="text"
              name="company"
              onChange={this.handleChange}
              className={Styles.input}
            />
          </label>
        </div>
        <div className={Styles.inputRow}>
          <label htmlFor={"action"}>
            What would you like to do next?*
            <br />
            <Select
              placeholder={"Pick from the list"}
              required={true}
              name={"action"}
              styles={selectCustomStyles}
              className={Styles.select}
              value={selectedOption}
              onChange={this.handleSelectChange}
              options={selectOptions}
            />
          </label>
        </div>

        <p>
          <button
            className={Styles.inputSubmit}
            disabled={!canSubmit}
            type="submit"
          >
            Submit
          </button>
        </p>
      </form>
    )
  }
}
export default ContactForm
