import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import Styles from "./stat-card.module.scss"

const StatCard = ({ stat, description }) => {
  return (
    <div className={cn(Styles.statCard)}>
      {stat && <h4 className={`${Styles.statCard__stat}`}>{stat}</h4>}
      {description && (
        <div className={Styles.statCard__content}>
          <RichText render={description} />
        </div>
      )}
    </div>
  )
}

StatCard.propTypes = {
  stat: PropTypes.string,
  description: PropTypes.array,
}

export default StatCard
