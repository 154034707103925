import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./text.module.scss"

const Text = ({ text }) => {
  return (
    <div className={Styles.Text}>
      <div className={Styles.Text__container}>
        <RichText
          render={text}
          linkResolver={linkResolver}
          htmlSerializer={htmlSerializer}
        />
      </div>
    </div>
  )
}

Text.propTypes = {
  text: PropTypes.array,
}

Text.defaultProps = {
  text: [],
}

export default Text
