import React, { } from "react"
import StaticPageMaxWithWrapper from "../static-page-max-width-wrapper/static-page-max-width-wrapper"
import Styles from "./static-section-built-for.module.scss"
import ShieldSvg from "./shield-svg"
import ScaleSvg from "./scale-svg"
import SecuritySvg from "./security-svg"
import FlexibleSvg from "./flexible-svg"
import LinkArrow from "../link-arrow/link-arrow"

const items = [
  {
    icon: ScaleSvg,
    title: "Unparalleled scale",
    description: "CaptionHub's infrastructure and application architecture powers the world's most demanding subtitling and workflow requirements, without missing a beat."
  }, {
    icon: SecuritySvg,
    title: "Quality and accuracy",
    description: "We responsibly leverage AI to enhance caption accuracy, tailored specifically to your language domain, ensuring precision and relevance in communication."
  },
  {
    icon: FlexibleSvg,
    title: "Completely flexible",
    description: "Customise CaptionHub to fit your business and give you the controls you need with policies, roles, and approval workflows."
  },
  {
    icon: ShieldSvg,
    title: "Security by design",
    description: "Your data security is our top priority. We have ISO 27001 certification and Cyber Essentials Plus, and we’re GDPR compliant. So you can rest assured that your content and data is safe."
  }
]

const StaticBuiltFor = ({ children, ...props }) => {
  return (
    <section className={Styles.builtFor} {...props}>
      <StaticPageMaxWithWrapper>
        <div className={Styles.flexWrapper}>
          <div className={Styles.headingWrapper}>
            <h2 className={Styles.heading}>
              Built for enterprise scale
            </h2>
            <p className={Styles.subHeading}>Whether you’re building your business, expanding internationally, or cutting costs—CaptionHub works for you.</p>
            <LinkArrow to="/why-captionhub/technology-ecosystem">Why CaptionHub</LinkArrow>
          </div>
          <div className={Styles.items}>
            {items.map((item, index) => {
              return (
                <div key={index} className={Styles.items__item}>
                  <item.icon />
                  <h3 className={Styles.items__title}>{item.title}</h3>
                  <p className={Styles.items__description}>{item.description}</p>
                </div>
              )
            })}
          </div>
        </div>
      </StaticPageMaxWithWrapper>
    </section >
  )
}


export default StaticBuiltFor
