import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./hero.module.scss"
import Lockup from "../lockup/lockup"
import ButtonGroup from "../button-group/button-group"

const Hero = ({ headline, summary, buttons }) => {
  return (
    <div className={Styles.Hero}>
      <div className={Styles.Hero__container}>
        <Lockup
          tag="h1"
          title={headline ? headline : ""}
          text={
            summary
              ? RichText.asText(summary, linkResolver, htmlSerializer)
              : ""
          }
        />

        {buttons && <ButtonGroup buttons={buttons} />}
      </div>
    </div>
  )
}

Hero.propTypes = {
  headline: PropTypes.string,
  summary: PropTypes.array,
  buttons: PropTypes.array,
}

Hero.defaultProps = {
  headline: "Headline",
  summary: [],
  buttons: [],
}

export default Hero
