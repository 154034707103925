import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import { linkResolver } from "../../utils/linkResolver"
import Image from "../../elements/image/image"
import Wrapper from "../layout/wrapper/wrapper"
import Styles from "./testimonials-alternative.module.scss"
import Title from "../../elements/title/title"
import "./testimonials-alternative.slider.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const TestimonialsAlternative = ({ heading, items }) => {
  const content = items.map((item, key) => {
    return (
      <div key={key} className={Styles.testimonialsAlternative__item}>
        {item.heading && (
          <h4 className={Styles.testimonialsAlternative__itemHeading}>
            {item.heading}
          </h4>
        )}
        {item.description && (
          <div className={Styles.testimonialsAlternative__message}>
            <RichText render={item.description} linkResolver={linkResolver} />
          </div>
        )}
        <div className={Styles.testimonialsAlternative__wrapper}>
          <blockquote className={Styles.testimonialsAlternative__blockquote}>
            <div className={Styles.testimonialsAlternative__quote}>
              {item.quote && (
                <RichText render={item.quote} linkResolver={linkResolver} />
              )}
            </div>
            <cite className={Styles.testimonialsAlternative__cite}>
              <span className={Styles.testimonialsAlternative__author}>
                {item.author}
              </span>
              <span className={Styles.testimonialsAlternative__position}>
                &nbsp; {item.position}
              </span>
            </cite>
          </blockquote>
          <div className={Styles.testimonialsAlternative__logo}>
            {item.logo && (
              <Image
                sharp={item.logoSharp}
                alt={item.logo.alt || item.author}
              />
            )}
          </div>
        </div>
      </div>
    )
  })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Wrapper>
      <div className={Styles.testimonialsAlternative__heading}>
        <Title tag="h2" title={heading} />
      </div>
      <div
        className={cn(Styles.testimonialsAlternative, "testimonials-slider")}
      >
        <Slider
          {...settings}
          className={Styles.testimonialsAlternative__container}
        >
          {content}
        </Slider>
      </div>
    </Wrapper>
  )
}

TestimonialsAlternative.propTypes = {
  items: PropTypes.array,
  heading: PropTypes.string,
}

TestimonialsAlternative.defaultProps = {
  items: [],
  heading: null,
}

export default TestimonialsAlternative
