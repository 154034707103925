import React from "react"
import { RichText } from "prismic-reactjs"
import Styles from "./speakers.module.scss"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Image from "../../elements/image/image"
import Title from "../../elements/title/title"

const CtaPanel = ({ slice }) => {
  const { fields, primary } = slice
  const { heading, speaker_subheading, subheading, description_copy } = primary

  return (
    <div className={Styles.speakers}>
      <div className={Styles.speakers__container}>
        <Title tag={"h2"} title={heading} />
        <div className={Styles.speakers__wrapperInner}>
          <div className={Styles.speakers__description}>
            <Title tag={"h3"} title={subheading} />
            <RichText
              render={description_copy}
              linkResolver={linkResolver}
              htmlSerializer={htmlSerializer}
            />
          </div>
          <div className={Styles.speakers__list}>
            <Title tag={"h3"} title={speaker_subheading?.toUpperCase()} />
            <div className={Styles.speakers__bios}>
              {fields.map((field, i) => (
                <div key={i} className={Styles.bioWrapper}>
                  <div className={Styles.imageWrapper}>
                    <Image sharp={field?.imageSharp} alt={field?.image?.alt} />
                  </div>
                  <div>
                    <p className={Styles.name}>{field?.name}</p>
                    <p className={Styles.caption}>{field?.caption}</p>
                    <p className={Styles.company}>{field?.company}</p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CtaPanel
