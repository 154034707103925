import React from 'react';

const AllianzSvg = () => {
    return (
        <svg width="129" height="32" viewBox="0 0 129 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_271_1528)">
                <path d="M11.7473 16.9378H7.34201L9.68828 9.28255L11.7473 16.9378ZM5.05707 25.374L6.561 20.3329H12.496L13.9032 25.3611H19.3896L13.9387 6.19077C13.6159 5.05798 12.9382 4.53516 11.7473 4.53516H3.96623V6.34568H4.6117C5.42498 6.34568 5.8639 6.66842 5.8639 7.28483C5.8639 7.6947 5.77354 8.0368 5.4508 8.9695L-0.00982666 25.374H5.05707Z" fill="currentColor" />
                <path d="M25.7378 25.374V6.13268C25.7378 5.12898 25.1504 4.53516 24.179 4.53516H19.3541V6.34568H19.6769C20.4869 6.34568 20.8322 6.69101 20.8322 7.51075V25.374H25.7378Z" fill="currentColor" />
                <path d="M34.0772 25.374V6.13268C34.0772 5.12898 33.4801 4.53516 32.5151 4.53516H27.7064V6.34568H28.0292C28.8424 6.34568 29.1878 6.69101 29.1878 7.51075V25.374H34.0772Z" fill="currentColor" />
                <path d="M42.6328 6.43588C42.6328 5.03845 41.5968 4.17676 39.8218 4.17676C38.0468 4.17676 37.0043 5.02232 37.0043 6.43588C37.0043 7.84945 38.0726 8.69501 39.8218 8.69501C41.571 8.69501 42.6328 7.85591 42.6328 6.43588ZM42.5392 25.3738V11.3479C42.5392 10.3474 41.9454 9.7568 40.974 9.7568H36.2266V11.5318H36.5073C37.3174 11.5318 37.6627 11.9062 37.6627 12.7195V25.3738H42.5392Z" fill="currentColor" />
                <path d="M53.2797 17.8734V20.9103C52.4632 21.7526 51.4692 22.269 50.6268 22.269C49.7845 22.269 49.4392 21.8365 49.4392 20.6553C49.4392 19.4741 49.6845 18.9739 50.5623 18.635C51.4364 18.2792 52.348 18.0237 53.2797 17.8734ZM46.683 14.2265C48.1822 13.4828 49.8226 13.0669 51.495 13.0066C52.8117 13.0066 53.2797 13.4745 53.2797 14.8397V15.1883C51.282 15.511 50.6559 15.6336 49.5295 15.8821C48.8987 16.0211 48.2811 16.2144 47.6835 16.4598C45.7116 17.2505 44.7789 18.7738 44.7789 21.1136C44.7789 24.0828 46.0699 25.5157 48.713 25.5157C49.6121 25.5194 50.5037 25.3518 51.3401 25.0219C52.0987 24.6766 52.8041 24.2245 53.4346 23.6793V23.8988C53.4346 24.867 53.9316 25.3608 54.903 25.3608H59.3729V23.6923H59.1534C58.3111 23.6923 58.0013 23.3147 58.0013 22.3174V14.8784C58.0013 10.9121 56.6232 9.53076 52.6504 9.53076C51.3909 9.53704 50.1361 9.68536 48.9099 9.9729C47.7514 10.2313 46.6354 10.6529 45.5954 11.2251L46.683 14.2265Z" fill="currentColor" />
                <path d="M66.4892 25.3735V14.8137C67.3057 13.9069 68.1448 13.497 69.113 13.497C70.1489 13.497 70.4039 13.9069 70.4039 15.4786V25.3735H75.2772V14.7556C75.2772 12.2835 75.0997 11.5025 74.309 10.6634C73.6635 9.93725 72.6533 9.56934 71.3656 9.56934C69.4583 9.56934 68.1157 10.1599 66.3117 11.8478V11.225C66.3117 10.2568 65.805 9.75652 64.8077 9.75652H60.1765V11.5315H60.4573C61.2673 11.5315 61.6159 11.9059 61.6159 12.7192V25.3735H66.4892Z" fill="currentColor" />
                <path d="M89.7323 22.095H82.9324L89.4354 12.9778V9.75049H79.2371C78.0817 9.75049 77.5492 10.2798 77.5492 11.4384V14.2784H79.3597V14.0009C79.3597 13.2812 79.7341 12.9391 80.5119 12.9391H84.0167L77.4459 22.1595V25.3869H89.7097L89.7323 22.095Z" fill="currentColor" />
                <path d="M115.315 24.9767V7.32006C115.315 5.91294 114.815 5.43853 113.45 5.43853H109.316V7.42333H109.564C110.507 7.42333 110.691 7.63633 110.691 8.77235V24.9767H115.315ZM117.355 24.9767H121.944V11.2251C121.944 9.84382 121.412 9.34681 120.066 9.34681H117.355V24.9767ZM108.664 24.9767V9.35972H105.943C104.597 9.35972 104.071 9.85673 104.071 11.238V24.9767H108.664ZM126.288 16.0016C126.288 23.7826 120.692 29.4401 113.008 29.4401C105.324 29.4401 99.7144 23.7826 99.7144 16.0016C99.7144 8.22048 105.32 2.56298 113.008 2.56298C120.695 2.56298 126.288 8.25275 126.288 16.0016ZM129.006 16.0016C129.006 6.85209 122.16 0.000488281 113.008 0.000488281C103.855 0.000488281 97.0035 6.85209 97.0035 16.0016C97.0035 25.151 103.849 31.9994 113.008 31.9994C122.167 31.9994 129.006 25.1736 129.006 16.0016Z" fill="currentColor" />
            </g>
            <defs>
                <clipPath id="clip0_271_1528">
                    <rect width="129.015" height="32" fill="currentColor" transform="translate(-0.00982666)" />
                </clipPath>
            </defs>
        </svg>

    );
};

export default AllianzSvg;
