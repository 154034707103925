import React from "react"
import Styles from "./cta-panel.module.scss"

const CtaPanel = props => {
  return (
    <div className={Styles.ctaPanel}>
      <div className={Styles.ctaPanel__container}>{props.children}</div>
    </div>
  )
}

export default CtaPanel
