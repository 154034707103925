import React from "react"
import PropTypes from "prop-types"
import Styles from "./signup-form-simple.module.scss"
import Wrapper from "../layout/wrapper/wrapper"
import { SplitContainer, SplitLeft, SplitRight } from "../layout/split/split"
import Title from "../../elements/title/title"
import Text from "../../elements/text/text"
import BenefitList from "../../elements/plain-list/plain-list"
import LogoWall from "../../components/logo-wall/logo-wall"
import SignUpForm from "./signup-form-simple"

const DemoSignupSimple = props => {
  const {
    headline,
    description,
    whatYoullGetHeading,
    benefitList,
    collaborationHeading,
    logos,
    bookADemoHeading,
    bookADemoDescription,
  } = props

  return (
    <Wrapper>
      <SplitContainer>
        <SplitLeft>
          <Title tag={"h1"} title={headline} color={"blue"} />
          <Text text={description} />
          <Title tag={"h2"} title={whatYoullGetHeading} />
          {benefitList && (
            <BenefitList modifierClass={"Demo"} listItems={benefitList} />
          )}
          <Title tag={"h2"} title={collaborationHeading} />
          <LogoWall modifierClass={"Demo"} items={logos} />
        </SplitLeft>
        <SplitRight>
          <div className={Styles.formContainer}>
            <Title tag={"h2"} title={bookADemoHeading} />
            <Text text={bookADemoDescription} />
            <SignUpForm formName={"demo-request"} />
          </div>
        </SplitRight>
      </SplitContainer>
    </Wrapper>
  )
}

DemoSignupSimple.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  whatYoullGetHeading: PropTypes.string,
  collaborationHeading: PropTypes.string,
  benefitList: PropTypes.array,
}

export default DemoSignupSimple
