import React, { Component } from "react"
import PropTypes from "prop-types"
import Styles from "./download.module.scss"
import CtaPanel from "../cta-panel/cta-panel"
import Button from "../button/button"
import FormInput from "../form-input/form-input"
import Image from "../../elements/image/image"
import ButtonStyles from "../../components/button/button.module.scss"
import cn from "classnames"

class Download extends Component {
  constructor(props) {
    super(props)
    this.DownloadForm = React.createRef()
    this.state = {}
  }
  encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&")
  }
  handleChange = e => {
    this.setState({ [e.target.name]: e.target.value, submitted: false })
  }

  handleSubmit = e => {
    e.preventDefault()
    const form = this.DownloadForm.current
    const { email } = this.state

    if (window.trackRoarEvent) {
      window.trackRoarEvent(email)
    }

    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: this.encode({
        "form-name": form.getAttribute("name"),
        email,
        pageId: this.props.uid,
      }),
    })
      .then(response => {
        this.setState({ submitted: true })
      })
      .catch(error => {
        console.log(`error in submiting the form data:${error}`)
      })
  }

  render() {
    const { headline, description, image, file, formId } = this.props
    const { submitted } = this.state

    return (
      <CtaPanel>
        <div className={Styles.download}>
          <div className={Styles.download__newsletter}>
            <h3 className={Styles.download__headline}>{headline}</h3>
            <p className={Styles.download__description}>{description}</p>
            {!submitted && (
              <form
                className={Styles.form}
                name={formId || "download"}
                method="post"
                action="/"
                data-netlify="true"
                data-netlify-honeypot="bot-field"
                onSubmit={this.handleSubmit}
                ref={this.DownloadForm}
              >
                <input type="hidden" name="form-name" value="download" />

                <div hidden>
                  <label htmlFor={"bot-field"}>
                    Don’t fill this out:{" "}
                    <input
                      name="bot-field"
                      onChange={this.handleChange}
                      className={Styles.input}
                    />
                  </label>
                </div>
                <div className={Styles.download__fields}>
                  <FormInput
                    name={"email"}
                    label="Enter your email"
                    id="download-email"
                    type="email"
                    placeholder="Enter your email"
                    required={true}
                    onChange={this.handleChange}
                  />
                  <input type="hidden" name="pageId" value={this.props.uid} />
                  <Button element="button" theme="Dark" buttonType={"submit"}>
                    Submit
                  </Button>
                </div>
              </form>
            )}
            {submitted && (
              <>
                <p>Thanks for submitting your details</p>
                <a
                  target={"_blank"}
                  download={"foo"}
                  className={cn(ButtonStyles.Button, ButtonStyles.ButtonSolid)}
                  href={file.url}
                >
                  Download
                </a>
              </>
            )}
            <p className={Styles.download__terms}>
              By clicking the Submit button you are agreeing to receive email
              communications from us from time to time. We will not share your
              details with any 3rd parties. Please review our Privacy Policy for
              more information.
            </p>
          </div>
          <div className={Styles.download__image}>
            <Image sharp={image} alt="Download" />
          </div>
        </div>
      </CtaPanel>
    )
  }
}

Download.propTypes = {
  headline: PropTypes.string,
  description: PropTypes.string,
  file: PropTypes.object,
  image: PropTypes.object,
}

Download.defaultProps = {
  headline: "",
  description: "",
  file: {},
  image: {},
}

export default Download
