import React from "react"
import PropTypes from "prop-types"
import ReactPlayer from "react-player"
import Styles from "./video.module.scss"

const Video = props => {
  const urlParams = new URLSearchParams(props?.url)
  const controls = urlParams.get("controls")
  const overrideControls = controls == 1
  return overrideControls ? (
    <ReactPlayer
      playing={true}
      url={props.url}
      className={Styles.video}
      width={"100%"}
      height={"100%"}
      controls={true}
    />
  ) : (
    <ReactPlayer
      playing={true}
      url={props.url}
      className={Styles.video}
      width={"100%"}
      height={"100%"}
    />
  )
}

Video.propTypes = {
  url: PropTypes.string,
}

Video.defaultProps = {
  url: "",
}

export default Video
