import React from 'react';

const NextSvg = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.5 24C0.5 11.0213 11.0213 0.5 24 0.5C36.9787 0.5 47.5 11.0213 47.5 24C47.5 36.9787 36.9787 47.5 24 47.5C11.0213 47.5 0.5 36.9787 0.5 24Z" stroke="white" stroke-opacity="0.5" />
            <path d="M14 24H34" stroke="#E9E9E9" stroke-width="2" stroke-miterlimit="10" />
            <path d="M27 17L34 24L27 31" stroke="#E9E9E9" stroke-width="2" stroke-miterlimit="10" stroke-linecap="square" />
        </svg>


    );
};

export default NextSvg;
