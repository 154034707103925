import React from "react"
import HeadingAndCtaRow from "../../components/heading-and-cta-row/heading-and-cta-row"
import { getPageUrl } from "../../utils/helpers"

export default ({ slice }) => {
  const { headline, cta_link, cta_text } = slice.primary
  const isBlogPost = cta_link?._meta?.type === "blog_post"
  const linkPrefix = isBlogPost ? "/blog-post" : ""

  return (
    <HeadingAndCtaRow
      headline={headline}
      ctaLink={cta_link ? linkPrefix + getPageUrl(cta_link) : null}
      ctaText={cta_text}
    />
  )
}
