import React from "react"
import PropTypes from "prop-types"
import Styles from "./text.module.scss"

const Text = props => <p className={Styles.text}>{props.text}</p>

Text.propTypes = {
  text: PropTypes.string,
}

Text.defaultProps = {
  text: "",
}

export default Text
