import React from "react"
import Styles from "./split.module.scss"
import cn from "classnames"

export const SplitContainer = props => {
  return <div className={`${Styles.splitContainer}`}>{props.children}</div>
}

export const SplitLeft = props => {
  return (
    <div className={`${Styles.splitContentContainerLeft}`}>
      {props.children}
    </div>
  )
}

export const SplitRight = props => {
  return (
    <div
      className={cn([
        Styles.splitContentContainerRight,
        props.type === "contact" && Styles.splitContentContainerRightContact,
      ])}
    >
      {props.children}
    </div>
  )
}
