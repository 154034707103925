import React from 'react';

const ShieldSvg = () => {
    return (
        <svg width="36" height="42" viewBox="0 0 36 42" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0.5V20.5H0V6.5L18 0.5Z" fill="url(#paint0_linear_315_1113)" />
            <path d="M36 6.5V20.5H18V0.5L36 6.5Z" fill="url(#paint1_linear_315_1113)" />
            <path d="M36 20.5C36 33.5 18 41.5 18 41.5V20.5H36Z" fill="url(#paint2_linear_315_1113)" />
            <path d="M18 20.5V41.5C18 41.5 0 33.5 0 20.5H18Z" fill="url(#paint3_linear_315_1113)" />
            <defs>
                <linearGradient id="paint0_linear_315_1113" x1="-14.277" y1="-9.03199" x2="21.38" y2="20.888" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_315_1113" x1="8.56201" y1="21.145" x2="43.844" y2="0.775001" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_315_1113" x1="48.354" y1="43.329" x2="13.707" y2="23.325" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint3_linear_315_1113" x1="3.153" y1="37.968" x2="29.408" y2="6.67901" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default ShieldSvg;