import React from 'react';

const FordSvg = () => {
    return (
        <svg width="98" height="80" viewBox="0 0 98 80" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_519_72)">
                <path d="M51.8132 33.7364H46.0631V37.9535H51.8132V33.7364ZM54.5956 52.5581L51.8132 49.7674V41.8605H46.0631V46.0775H48.1035V52.5891H33.2644V46.0775H36.0467V33.7364H33.2644V27.2248H58.1199L62.2625 31.4419V36.7132L58.5527 39.4419L62.2625 42.2326V46.9767H64.612V52.5581H54.5956ZM62.3243 6.51163V19.7519L56.7596 25.3333H41.6114L36.0467 19.7519V6.51163H33.2644V0H48.5363V6.51163H46.1249V18.8527H54.6265V6.51163H52.5552V0H64.6738V6.51163H62.2934M87.9527 6.51163H79.4511V10.8527H87.9836V6.51163H87.9527ZM93.9811 15.2868H79.4511V18.7907H82.1407V25.3023H66.6214V18.7907H69.4038V6.51163H66.6214V0H93.5173L98 4.34109V11.2868L93.9811 15.2558M92.3117 52.5581H73.2063L66.6214 46.0465V33.7054L73.2063 27.1938H88.2618L90.1167 29.5814L91.9716 27.2248H98V37.8915H90.1167V33.7054H76.8852V46.1085H90.1167V41.8605H98V46.9767L92.3117 52.5581ZM61.8915 79.907H33.2644V73.3953H36.0467V61.0853H33.2644V54.5736H48.8454V61.0853H46.0631V73.4264H56.8214V65.2403H64.6738V76.8992L61.8915 79.907ZM95.2177 79.907H66.5905V73.3953H69.311V61.0853H66.5905V54.5736H82.1098V61.0853H79.3274V73.4264H90.1476V65.2403H98V76.8992L95.2177 79.907ZM26.4631 25.3333H8.9653L6.89401 23.1008L5.03912 25.3333H0V17.5814H7.04858V19.7519H20.2492V16.124H4.54448L0 11.876V5.67442L5.25552 0H20.9603L23.0315 2.23256L24.8864 0H29.9256V7.75194H22.8151V5.5814H10.9438V9.24031H26.2776L31.4095 14.1085V20L26.4631 25.3333ZM28.5653 52.5581H0V46.0465H2.78233V33.7984H0V27.2868H28.5653L31.3476 30.3876V39.0698L23.4953 39.1318V33.7984H12.7987V37.5194H17.436V42.2326H12.7987V46.0775H23.4953V40.8062H31.3476V49.4884L28.5653 52.5891M28.5653 79.876H0V73.3643H2.78233V61.0853H0V54.5736H28.5653L31.3476 57.6744V66.3566H23.4953V61.0853H12.7987V64.8992H17.436V69.5504H12.7987V73.4884H23.4953V68.2171H31.3476V76.8992L28.5653 80" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_519_72">
                    <rect width="98" height="80" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default FordSvg;