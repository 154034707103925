import React from "react"
import PropTypes from "prop-types"
import Text from "../../elements/text/text"
import Title from "../../elements/title/title"

const Lockup = ({ tag, title, text, ...props }) => {
  return (
    <>
      {title.length ? <Title tag={tag} title={title} /> : null}
      {text.length ? <Text text={text} /> : null}
      {props.children}
    </>
  )
}

Lockup.propTypes = {
  tag: PropTypes.oneOf(["h1", "h2", "h3", "h4", "h5", "h6"]),
  title: PropTypes.string,
  text: PropTypes.string,
}

Lockup.defaultProps = {
  tag: "h6",
  title: "",
  text: "",
}

export default Lockup
