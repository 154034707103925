import React, { useState } from "react"
import PropTypes from "prop-types"
import Styles from "./button-group.module.scss"
import Button from "../button/button"
import Overlay from "../overlay/overlay"
import Video from "../video/video"
import IconPlay from "../../images/play-button.svg"
import { getPageUrl } from "../../utils/helpers"

const ButtonGroup = ({ buttons, theme }) => {
  const [overlay, triggerOverlay] = useState(false)

  const loadVideo = () => {
    triggerOverlay(true)
  }

  if (!buttons.length) {
    return null
  }

  const items = buttons
    .filter(item => item.title)
    .map((item, key) => {
      const { title, link, video } = item

      if (!title) {
        return null
      }

      if (video) {
        return (
          <li key={key.toString()} className={`${Styles.ButtonGroup__button}`}>
            {overlay && (
              <Overlay closeAction={() => triggerOverlay(false)}>
                <Video url={video.embed_url} />
              </Overlay>
            )}

            <span onClick={() => loadVideo()}>
              <Button
                element="button"
                type={theme === "Light" ? "Solid" : "Normal"}
                icon={IconPlay}
              >
                {title}
              </Button>
            </span>
          </li>
        )
      }

      if (link && link._linkType === "Link.web") {
        return (
          <li key={key.toString()} className={`${Styles.ButtonGroup__button}`}>
            <Button
              link={link.url}
              target="_blank"
              destination="external"
              type={theme === "Light" ? "Solid" : "Normal"}
            >
              {title}
            </Button>
          </li>
        )
      }

      if (link && link._linkType === "Link.document") {
        return (
          <li key={key.toString()} className={`${Styles.ButtonGroup__button}`}>
            <Button
              link={getPageUrl(link)}
              destination="internal"
              type={theme === "Light" ? "Solid" : "Normal"}
            >
              {title}
            </Button>
          </li>
        )
      }

      return (
        <li key={key.toString()} className={`${Styles.ButtonGroup__button}`}>
          <Button
            element="button"
            type={theme === "Light" ? "Solid" : "Normal"}
          >
            {title}
          </Button>
        </li>
      )
    })
  return <ul className={`${Styles.ButtonGroup}`}>{items}</ul>
}

ButtonGroup.propTypes = {
  buttons: PropTypes.array,
  theme: PropTypes.string,
}

ButtonGroup.defaultProps = {
  buttons: [],
  theme: "Dark",
}

export default ButtonGroup
