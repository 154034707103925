import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import Styles from "./graphic-info-card.module.scss"
import BackgroundImage from "gatsby-background-image"
import Link from "../../elements/link/link"

const InfoCard = ({
  heading,
  description,
  sharp,
  backgroundColor,
  linkObject,
  readMoreLabel,
}) => {
  const imageData = sharp.childImageSharp.fluid

  return (
    <div className={cn(Styles.graphicInfoCard)}>
      {sharp && (
        <div style={{ background: backgroundColor }}>
          <BackgroundImage
            Tag="div"
            className={Styles.graphic}
            fluid={imageData}
          />
        </div>
      )}

      <h4 className={`${Styles.graphicInfoCard__heading}`}>{heading}</h4>

      <div className={`${Styles.graphicInfoCard__descriptionWrapper}`}>
        {description && <RichText render={description} />}

        {linkObject && (
          <Link
            className={Styles.graphicInfoCard__readMore}
            linkObject={linkObject}
            label={readMoreLabel && readMoreLabel}
          />
        )}
      </div>
    </div>
  )
}

InfoCard.propTypes = {
  headline: PropTypes.string,
  subtitle: PropTypes.string,
  description: PropTypes.array,
  sharp: PropTypes.object,
  linkObject: PropTypes.object,
  readMoreLabel: PropTypes.string,
}

export default InfoCard
