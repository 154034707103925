import React, { useRef } from "react"
import StaticSectionHeading from "../static-section-heading/static-section-heading"
import StaticPageMaxWithWrapper from "../static-page-max-width-wrapper/static-page-max-width-wrapper"
import Styles from "./static-section-feature-summary.module.scss"
import UnifiedPlatform from "./unified-platform"
import PerfectSubtitles from "./perfect-subtitles"
import Enterprise from "./enterprise"
import gsap from "gsap";
import { useGSAP } from "@gsap/react";

gsap.registerPlugin(useGSAP);


const items = [{
  icon: UnifiedPlatform,
  title: "All the tools in one platform",
  description: "CaptionHub brings together the features, integrations and people required for a seamless and powerful subtitling experience."
}, {
  icon: PerfectSubtitles,
  title: "Localise all your media, at speed",
  description: "CaptionHub just works faster. Through our AI powered engines, our workflow automation and our simple UI, you’ll be slashing turnaround times."
},
{
  icon: Enterprise,
  title: "Enterprise-grade security",
  description: "Our state-of-the-art encryption and security has been designed to ensure that your data is only accessible by those you give permission to."
}
]

const StaticSectionFeatureSummary = ({ children, ...props }) => {
  const container = useRef();

  // Initialize the GSAP animation for the slider containers
  useGSAP(() => {
    // Select all the slider containers
    const sliderContainers = document.querySelectorAll('.slider-container');

    // Loop through each container
    sliderContainers.forEach(container => {
      // Define the options for the vertical slider animation
      const vsOpts = {
        slides: container.querySelectorAll('.v-slide'),
        list: container.querySelector('.v-slides'),
        duration: 0.6,
        lineHeight: 60,
      };

      // Create a timeline for the vertical slider animation
      const vSlide = gsap.timeline({
        paused: true,
        repeat: -1,
      });

      // Loop through each slide
      vsOpts.slides.forEach((slide, i) => {
        let label = "slide" + i;
        vSlide.add(label);

        // Animate the slide and list elements
        if (i > 0) {
          vSlide.to(vsOpts.list, {
            duration: vsOpts.duration,
            y: i * -1 * vsOpts.lineHeight,
          }, label);

          vSlide.from(slide, {
            duration: vsOpts.duration,
            y: 60,
          }, label);
        }

        // Add a delay before animating the next slide
        if (i < vsOpts.slides.length - 1) {
          vSlide.to(slide, {
            duration: vsOpts.duration,
            y: -60,
          }, "+=4"); // Add 2 second delay here
        }
      });

      // Play the vertical slider animation
      vSlide.play();
    });
  }, { scope: container });


  return (
    <section className={Styles.featureSummary} {...props}>
      <StaticPageMaxWithWrapper>
        <div className={Styles.mobileHeader}>
          <StaticSectionHeading>
            <h2>Perfect multimedia localisation for brands and language agencies</h2>
            <p>CaptionHub helps you communicate more quickly and with greater clarity and accuracy to all your audiences, even when they’re multitasking or on the move.</p>
          </StaticSectionHeading>
        </div>
        <div className={Styles.desktopHeader}>
          <StaticSectionHeading>
            <h2>
              <div className={`${Styles.wordSlider} slider-container`}>
                <div className={`${Styles.vSlides} v-slides`}>
                  <div className={`${Styles.vSlide} v-slide`}>Perfect subtitles,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Translated voiceover,</div>
                  <div className={`${Styles.vSlide} v-slide`}>On screen text captioning,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Automated multilingal subtitles,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Mutlilingual voice published,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Subtitle publishing,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Burnt in subtitles,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Real-time subtitles,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Editing and workflow,</div>
                  <div className={`${Styles.vSlide} v-slide`}>Perfect subtitles,</div>
                </div>
              </div><br /><span className={Styles.connector}>for{" "}</span>
              <div className={`${Styles.wordSlider} slider-container`}>
                <div className={`${Styles.vSlides} v-slides`}>
                  <div className={`${Styles.vSlide} v-slide`}>everyone</div>
                  <div className={`${Styles.vSlide} v-slide`}>podcasts</div>
                  <div className={`${Styles.vSlide} v-slide`}>e-learning</div>
                  <div className={`${Styles.vSlide} v-slide`}>video back catalogues</div>
                  <div className={`${Styles.vSlide} v-slide`}>Brightcove, Vimeo and Kaltura videos</div>
                  <div className={`${Styles.vSlide} v-slide`}>YouTube</div>
                  <div className={`${Styles.vSlide} v-slide`}>social media</div>
                  <div className={`${Styles.vSlide} v-slide`}>live streaming events</div>
                  <div className={`${Styles.vSlide} v-slide`}>linguists and language vendors</div>
                  <div className={`${Styles.vSlide} v-slide`}>everyone</div>
                </div>
              </div>
            </h2>

            <p>CaptionHub helps you communicate with greater clarity to all your audiences, even when they're multitasking or on the move.</p>
          </StaticSectionHeading>
        </div>

        <div className={Styles.items}>
          {items.map((item, index) => (
            <div key={index} className={Styles.item}>
              <item.icon />
              <h3>{item.title}</h3>
              <p>{item.description}</p>
            </div>
          ))}
        </div>

        <div className={Styles.badges}>
          <a href="https://www.capterra.com/p/159104/CaptionHub/reviews/"> <img border="0" src="https://brand-assets.capterra.com/badge/85447660-5472-4605-a234-3c3695894568.svg" /> </a>
          <a href="https://www.getapp.com/collaboration-software/a/captionhub/reviews/ "> <img border="0" src="https://brand-assets.getapp.com/badge/47cee092-56f3-404b-baa4-2c8ebdc4e4ce.png" /> </a>
        </div>
      </StaticPageMaxWithWrapper >


    </section >
  )
}


export default StaticSectionFeatureSummary
