import React from "react"
import TabsTrio from "../../components/tabs-trio/tabs-trio"

export default ({ slice }) => {
  const {
    image,
    imageSharp,
    blue_title,
    blue_text,
    green_title,
    green_text,
    red_title,
    red_text,
  } = slice.primary

  return (
    <TabsTrio
      image={image}
      sharp={imageSharp}
      blueTitle={blue_title}
      blueText={blue_text}
      greenTitle={green_title}
      greenText={green_text}
      redTitle={red_title}
      redText={red_text}
    />
  )
}
