import React from 'react';

const SecuritySvg = () => {
    return (
        <svg width="36" height="44" viewBox="0 0 36 44" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M18 0L0 6V23C0 36 18 44 18 44C18 44 36 36 36 23V6L18 0Z" fill="url(#paint0_linear_1_6)" />
            <path d="M11.172 27.3638L24.607 13.9288C25.779 12.7568 27.678 12.7568 28.85 13.9288L30.557 15.6358L17.121 29.0708C15.949 30.2428 14.05 30.2428 12.878 29.0708L11.172 27.3638Z" fill="url(#paint1_linear_1_6)" />
            <path d="M5.10095 21.293L11.1719 27.364L14.9999 23.536L11.0499 19.586C9.87795 18.414 7.97895 18.414 6.80695 19.586L5.10095 21.293Z" fill="url(#paint2_linear_1_6)" />
            <defs>
                <linearGradient id="paint0_linear_1_6" x1="-7.60299" y1="47.844" x2="49.906" y2="-20.692" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.8" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_1_6" x1="4.5" y1="21.5" x2="38.6256" y2="21.5033" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.8" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_1_6" x1="3.03895" y1="14.125" x2="16.655" y2="30.3502" gradientUnits="userSpaceOnUse">
                    <stop stopColor="white" stopOpacity="0.8" />
                    <stop offset="1" stopColor="white" stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>


    );
};

export default SecuritySvg;