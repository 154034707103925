import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"

function getInternalUrl(linkObject) {
  const parent = linkObject.parent ? linkObject.parent._meta.uid : ""
  const slug = linkObject._meta.uid
  const url = parent ? `/${parent}/${slug}` : `/${slug}`

  return url
}

const LinkComponent = ({ linkObject, label = "Read more", ...rest }) => {
  const { _linkType, url } = linkObject

  if (!_linkType) return null

  const isExternalLink = _linkType === "Link.web" || _linkType === "Link.file"

  return isExternalLink ? (
    <a href={url} {...rest} target="_blank">
      {label}
    </a>
  ) : (
    <Link to={getInternalUrl(linkObject)} {...rest}>
      {label}
    </Link>
  )
}

LinkComponent.propTypes = {
  label: PropTypes.string,
  linkObject: PropTypes.object,
}

LinkComponent.defaultProps = {
  label: "Read more",
  linkObject: {},
}

export default LinkComponent
