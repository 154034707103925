import React from "react"
import DemoSignup from "../../components/demo-signup"

export default ({ slice, benefitList }) => {
  const {
    headline,
    description,
    what_youllget_heading,
    collaboration_heading,
    book_a_demo_heading,
    book_a_demo_description,
  } = slice.primary

  return (
    <DemoSignup
      headline={headline}
      description={description}
      benefitList={benefitList}
      collaborationHeading={collaboration_heading}
      whatYoullGetHeading={what_youllget_heading}
      bookADemoHeading={book_a_demo_heading}
      bookADemoDescription={book_a_demo_description}
      logos={slice.fields}
    />
  )
}
