import React from 'react';

const WorldSvg = () => {
    return (
        <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M20 40C31.0457 40 40 31.0457 40 20C40 8.9543 31.0457 0 20 0C8.9543 0 0 8.9543 0 20C0 31.0457 8.9543 40 20 40Z" fill="url(#paint0_linear_269_69)" />
            <path d="M30 20C30 18.327 29.95 16.653 29.851 15H39.37C39.01 13.6 38.5 12.26 37.86 11H29.502C29.118 7.57098 28.518 4.33098 27.69 1.53998C26.23 0.91998 24.68 0.47998 23.06 0.22998C24.041 2.78398 24.951 6.43298 25.491 11H14.509C15.049 6.43298 15.959 2.78398 16.94 0.22998C15.32 0.47998 13.77 0.91998 12.31 1.53998C11.482 4.33098 10.882 7.57098 10.498 11H2.14C1.5 12.26 0.990005 13.6 0.630005 15H10.149C10.05 16.653 10 18.327 10 20C10 21.673 10.05 23.347 10.149 25H0.630005C0.990005 26.4 1.5 27.74 2.14 29H10.498C10.882 32.429 11.482 35.669 12.31 38.46C13.77 39.08 15.32 39.52 16.94 39.77C15.959 37.216 15.049 33.567 14.509 29H25.491C24.951 33.567 24.041 37.216 23.06 39.77C24.68 39.52 26.23 39.08 27.69 38.46C28.518 35.669 29.118 32.429 29.502 29H37.86C38.5 27.74 39.01 26.4 39.37 25H29.851C29.95 23.347 30 21.673 30 20ZM14.153 25C14.055 23.416 14 21.75 14 20C14 18.25 14.055 16.584 14.153 15H25.847C25.945 16.584 26 18.25 26 20C26 21.75 25.945 23.416 25.847 25H14.153Z" fill="url(#paint1_linear_269_69)" />
            <defs>
                <linearGradient id="paint0_linear_269_69" x1="-2.044" y1="-2.044" x2="50.828" y2="50.828" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_269_69" x1="-5.56899" y1="-10.472" x2="51.869" y2="57.9799" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="0.745" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default WorldSvg;