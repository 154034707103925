import React from "react"
import PropTypes from "prop-types"
import styled, { keyframes } from "styled-components"
import Styles from "./logo-carousel.module.scss"
import LinkArrow from "../link-arrow/link-arrow"



const rotate = offset => keyframes` 
  0% {
    transform: translate3d(0, 0, 0);
  }

  100% {
    transform: translate3d(-${offset}px, 0, 0);
  }
`

const Root = styled.div`
  overflow: hidden;
`

const ImgTicker = styled.div`
  animation: ${({ keyframeAnimation }) => keyframeAnimation} 40s linear infinite;
  
`

const StyledImg = styled.img`
  width: ${({ width }) => width}px;
  min-width: ${({ width }) => width}px;
  margin-left: ${({ gutterSize }) => gutterSize / 2}px;
  margin-right: ${({ gutterSize }) => gutterSize / 2}px;
`

const LogoWall = ({ items }) => {
  const logoWidth = 100
  const gutterSize = 40
  const offset = items.length * logoWidth + items.length * gutterSize
  const keyframeAnimation = rotate(offset)

  const content = items.map((item, key) => {
    return (
      <StyledImg
        key={key}
        width={logoWidth}
        gutterSize={gutterSize}
        // fixed={item.imageSharp.childImageSharp.fixed}
        className={Styles.img}
        src={item.image.url}
        alt={item.image.alt || item.company}
      />
    )
  })

  return (
    <>
      <div className={Styles.centered}><p><span>1,000,000s</span> of minutes of multimedia content each year</p></div>
      <div>
        <div className={Styles.root}>
          <ImgTicker
            className={Styles.imgTicker}
            keyframeAnimation={keyframeAnimation}
          >
            {content}
            {content}
          </ImgTicker>
        </div>
      </div>
      <div className={Styles.centered}><LinkArrow to="/clients">See success stories</LinkArrow></div>
    </>
  )
}

LogoWall.propTypes = {
  items: PropTypes.array,
  modifierClass: PropTypes.string,
}

LogoWall.defaultProps = {
  items: [],
}

export default LogoWall
