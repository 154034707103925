import React from "react"
import StaticSectionHeading from "../static-section-heading/static-section-heading"
import StaticPageMaxWithWrapper from "../static-page-max-width-wrapper/static-page-max-width-wrapper"
import Styles from "./static-section-feature-detailed.module.scss"
import VoiceoverSvg from "./voiceover-svg"
import NoCodeSvg from "./no-code-svg"
import Button from "../button/button"
import LiveStreamSvg from "./live-stream-svg"
import LinkArrow from "../link-arrow/link-arrow"
import { StaticImage } from "gatsby-plugin-image"

const Image1 = () => (<StaticImage className={Styles.img} src="./feature.png" alt="Editor" quality={100} loading="eager" />)
const Image2 = () => (<StaticImage className={Styles.img} src="./editor.jpg" alt="Editor" loading="eager" />)

const items = [
  {
    title: "No-code automation",
    description: "Leverage CaptionHub's no-code subtitling-at-scale feature for instant transcription and translation of video content into multiple languages. Empower non-developers to create powerful, API-driven subtitling workflows effortlessly, automating the process from ingest to delivery for all videos.",
    url: "/features/automation",
    linkText: "Start automating",
    img: <Image1 />
  }, {
    title: "Create and edit captions in real time—with pinpoint accuracy",
    description: "Create and edit captions in real time. Our simple and intuitive editor requires no special skills or software, and offers advanced features usable by any team member, from producer to linguist.",
    url: "/features/editor",
    linkText: "Start editing",
    img: <Image2 />
  },

]

const subItems = [
  {
    icon: VoiceoverSvg,
    title: "Voiceover",
    description: "Natural text-to-speech in CaptionHub uses realistic AI voices to generate high-quality spoken audio in any voice, style, and language.",
    url: "/features/voiceover",
    linkText: "Create workflows"
  }, {
    icon: NoCodeSvg,
    title: "AI-powered speech-recognition and translation",
    description: "Using Natural Captions technology and multiple AI engines, CaptionHub transcribes and translates videos into hundreds of languages in seconds.",
    url: "/features/transcribe",
    linkText: "Start captioning",

  },
  {
    icon: LiveStreamSvg,
    title: "Live video subtitling ",
    description: "Live video event subtitling in 130 languages, with zero-latency and highly customisable AI for your company's terminology.",
    url: "/features/live",
    linkText: "More about live",

  }
]

const moreItems = ['Analytics', 'Burnt-in subtitles', 'Workflow management', 'Integrations and APIs']

const StaticSectionFeatureDetailed = ({ children, ...props }) => {

  return (
    <section className={Styles.featureDetailed} {...props}>
      <StaticPageMaxWithWrapper>
        <div className={Styles.gradient}>
          <StaticSectionHeading>
            <div className={Styles.headingContainer}><h2>All the tools you need in one platform</h2></div>
            <div className={Styles.copyButtonContainer} >
              <p>CaptionHub brings together the features, integrations and people required for end-to-end subtitling, voiceover and on-screen text localisation.</p>
              <div className={Styles.buttonContainer}>
                <Button type={"Solid"} link="/demo">Book a demo</Button>
                {/* <Button>See all features</Button> */}
              </div>
            </div>
          </StaticSectionHeading>

          <div className={Styles.items}>
            {items.map((item, index) => (
              <div key={index} className={Styles.item}>
                <>
                  <h3>{item.title}</h3>
                  <p>{item.description}</p>
                  <LinkArrow to={item.url}>{item.linkText}</LinkArrow>
                </>
                {item.img}
              </div>
            ))}
          </div>

          <div className={Styles.subItems}>
            {subItems.map((item, index) => (
              <div key={index} className={Styles.item}>
                <item.icon />
                <h3>{item.title}</h3>
                <p>{item.description}</p>
                <LinkArrow to={item.url}>{item.linkText}</LinkArrow>
              </div>
            ))}
          </div>

          <div className={Styles.more}>
            <p>And much more...</p>
            <div className={Styles.more__items}>
              {
                moreItems.map((item, index) => (
                  <div className={Styles.more__item}>
                    <div className={Styles.more__icon}>
                      <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M15 4.5L6.75 12.75L3 9" stroke="#00DDF1" stroke-width="2" stroke-linecap="square" />
                      </svg>
                    </div>
                    {item}
                  </div>)
                )
              }
            </div>
          </div>
        </div>
      </StaticPageMaxWithWrapper >
    </section >
  )
}

export default StaticSectionFeatureDetailed
