import React from 'react';

const ChatSvg = () => {
    return (
        <svg width="46" height="40" viewBox="0 0 46 40" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5 0C2.239 0 0 2.239 0 5V29L9.09999 23H27C29.761 23 32 20.761 32 18V0H5Z" fill="url(#paint0_linear_269_90)" />
            <path d="M46 15V40L36.9 34H19C16.239 34 14 31.761 14 29V23H27C29.761 23 32 20.761 32 18V10H41C43.761 10 46 12.239 46 15Z" fill="url(#paint1_linear_269_90)" />
            <path d="M26 6H10C8.343 6 7 7.343 7 9V10H23C24.657 10 26 8.657 26 7V6ZM20 13H10C8.343 13 7 14.343 7 16V17H17C18.657 17 20 15.657 20 14V13Z" fill="url(#paint2_linear_269_90)" />
            <defs>
                <linearGradient id="paint0_linear_269_90" x1="36.821" y1="31.971" x2="-24.582" y2="-19.553" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_269_90" x1="48.573" y1="54.169" x2="17.011" y2="-0.496993" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint2_linear_269_90" x1="32.223" y1="24.693" x2="2.017" y2="-0.651996" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>

    );
};

export default ChatSvg;