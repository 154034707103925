import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import { linkResolver } from "../../utils/linkResolver"
import htmlSerializer from "../../utils/htmlSerializer"
import Styles from "./graphic-hero.module.scss"
import Lockup from "../lockup/lockup"
import Image from "../../elements/image/image"
import Button from "../button/button"
import { getPageUrl } from "../../utils/helpers"

const GraphicHero = ({
  headline,
  summary,
  ctaText,
  ctaLink,
  featureImageSharp,
  featureImageAlt,
  thumbnailImageSharp,
  thumbnailImageAlt,
}) => {
  return (
    <div className={Styles.graphicHero}>
      <div className={Styles.graphicHero__container}>
        <div className={Styles.graphicHero__left}>
          {thumbnailImageSharp && (
            <div className={Styles.graphicHero__thumbnailImage}>
              <Image
                sharp={thumbnailImageSharp}
                alt={thumbnailImageAlt || headline}
              />
            </div>
          )}
          <div className={Styles.graphicHero__summary}>
            <Lockup
              tag="h1"
              title={headline ? headline : ""}
              text={
                summary
                  ? RichText.asText(summary, linkResolver, htmlSerializer)
                  : ""
              }
            />
          </div>

          {ctaText && ctaLink && (
            <div className={Styles.graphicHero__cta}>
              <Button
                target={ctaLink._linkType === "Link.file" && "_blank"}
                link={
                  ctaLink._linkType === "Link.file"
                    ? ctaLink.url
                    : getPageUrl(ctaLink)
                }
                destination={"external"}
              >
                {ctaText}
              </Button>
            </div>
          )}
        </div>
        <div className={Styles.graphicHero__right}>
          {featureImageSharp && (
            <div className={Styles.graphicHero__featureImage}>
              <Image
                sharp={featureImageSharp}
                alt={featureImageAlt || headline}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  )
}

GraphicHero.propTypes = {
  headline: PropTypes.string,
  summary: PropTypes.array,
  ctaText: PropTypes.string,
  featureImageAlt: PropTypes.string,
  thumbnailImageAlt: PropTypes.string,
  featureImageSharp: PropTypes.object,
  thumbnailImageSharp: PropTypes.object,
}

GraphicHero.defaultProps = {
  headline: "Headline",
  summary: [],
  ctaText: null,
  featureImageSharp: null,
  thumbnailImageSharp: null,
  featureImageAlt: null,
  thumbnailImageAlt: null,
}

export default GraphicHero
