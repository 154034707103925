import React from "react"
import Download from "../../components/download/download"

export default ({ slice, uid }) => {
  const { headline, description, file, imageSharp, form_id } = slice.primary
  return (
    <Download
      uid={uid}
      headline={headline}
      description={description}
      file={file}
      image={imageSharp}
      formId={form_id}
    />
  )
}
