import React from "react"
import PropTypes from "prop-types"
import cn from "classnames"

import Styles from "./heading-and-cta-row.module.scss"
import Title from "../../elements/title/title"
import ButtonGroup from "../button-group/button-group"
import Button from "../button/button"

const HeadingAndCtaRow = ({ headline, ctaText, ctaLink }) => {
  return (
    <div className={cn(Styles.headingAndCtaRow)}>
      <div className={Styles.headingAndCtaRow__container}>
        <Title tag="h2" title={headline} />

        {ctaLink && ctaText && (
          <div className={Styles.headingAndCtaRow__link}>
            <Button link={ctaLink}>{ctaText}</Button>
          </div>
        )}
      </div>
    </div>
  )
}

HeadingAndCtaRow.propTypes = {
  headline: PropTypes.string,
  link: PropTypes.string,
  ctaText: PropTypes.string,
}

HeadingAndCtaRow.defaultProps = {
  headline: "",
  ctaLink: null,
  ctaText: null,
}

export default HeadingAndCtaRow
