import React, { useState } from "react"
import PropTypes from "prop-types"
import { Tab, Tabs, TabList, TabPanel } from "react-tabs"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import Image from "../../elements/image/image"
import Overlay from "../../components/overlay/overlay"
import Video from "../../components/video/video"
import Styles from "./tabs.module.scss"
import "./react-tabs.scss"

const TabsComponent = ({ items }) => {
  const [videoUrl, changeUrl] = useState("")
  const [overlay, triggerOverlay] = useState(false)

  const loadVideo = video => {
    triggerOverlay(true)
    changeUrl(video.embed_url)
  }

  const tabsTabs = items.map((item, key) => {
    return (
      <Tab key={key.toString()} className={Styles.tabs__tab}>
        {item.tab}
      </Tab>
    )
  })

  const tabsPanels = items.map((item, key) => {
    return (
      <TabPanel key={key.toString()} className={Styles.tabs__panel}>
        <div className={Styles.tabs__inner}>
          {item.video && item.image && (
            <div
              className={`${Styles.tabs__image} ${Styles.tabs__imageVideo}`}
              onClick={() => loadVideo(item.video)}
            >
              <Image sharp={item.imageSharp} alt={item.image.alt || item.tab} />
            </div>
          )}

          {!item.video && item.image && (
            <div className={`${Styles.tabs__image}`}>
              <Image sharp={item.imageSharp} alt={item.image.alt || item.tab} />
            </div>
          )}
          <RichText render={item.text} />
        </div>
      </TabPanel>
    )
  })

  return (
    <div className={cn(Styles.tabs, "tabs")}>
      <Tabs>
        <TabList>{tabsTabs}</TabList>
        {tabsPanels}
      </Tabs>
      {overlay && (
        <Overlay closeAction={() => triggerOverlay(false)}>
          <Video url={videoUrl} />
        </Overlay>
      )}
    </div>
  )
}

TabsComponent.propTypes = {
  items: PropTypes.array,
}

TabsComponent.defaultProps = {
  items: [],
}

export default TabsComponent
