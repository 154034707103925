import React, { } from "react"
import StaticPageMaxWithWrapper from "../static-page-max-width-wrapper/static-page-max-width-wrapper"
import Styles from "./static-section-social-proof.module.scss"
import CCSvg from "./cc-svg"
import WorldSvg from "./world-svg"
import TickSvg from "./tick-svg"
import ChatSvg from "./chat-svg"
import Supercell from "./supercell-svg"

const items = [
  {
    icon: CCSvg,
    title: "+1M",
    description: "Minutes of captions and subtitles procued annually"
  }, {
    icon: WorldSvg,
    title: "68",
    description: "Languages and hundreds of voices supported by Voiceover"
  },
  {
    icon: TickSvg,
    title: "+40,000",
    description: "Number of linguists supported by a single client workflow"
  },
  {
    icon: ChatSvg,
    title: "316",
    description: "316 languages supported across transcription and translation"
  }
]

const StaticSocialProof = ({ children, ...props }) => {
  return (
    <section className={Styles.socialProof} {...props}>
      <StaticPageMaxWithWrapper >
        <div className={Styles.logoContainer}>
          <Supercell className={Styles.Supercell} />
        </div>
        <h2 className={Styles.quote}>
          “Subtitling non-scripted videos is a complex task, and in our fast-paced environment, every minute of time saved counts. Auto-transcription, timing, and integration with our translation tool are all features that save on the management side. Time we now get to spend on the translation of our videos.”
        </h2>
        <div className={Styles.author}>Silvio Clausen</div>
        <div className={Styles.bio}>Head of Localisation, <span>Supercell</span></div>
        <div className={Styles.items}>
          {items.map((item, index) => {
            return (
              <div key={index} className={Styles.items__item}>
                <item.icon />
                <h3 className={Styles.items__title}>{item.title}</h3>
                <p className={Styles.items__description}>{item.description}</p>
              </div>
            )
          })}
        </div>
      </StaticPageMaxWithWrapper >
    </section >
  )
}


export default StaticSocialProof
