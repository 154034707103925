import React from "react"
import PropTypes from "prop-types"
import { RichText } from "prismic-reactjs"
import cn from "classnames"
import { linkResolver } from "../../utils/linkResolver"
import Image from "../../elements/image/image"
import Wrapper from "../layout/wrapper/wrapper"
import Styles from "./testimonials.module.scss"
import "./testimonials.slider.scss"
import Slider from "react-slick"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"

const Testimonials = ({ theme, items }) => {
  const content = items.map((item, key) => {
    return (
      <div key={key} className={Styles.testimonials__item}>
        <div className={Styles.testimonials__wrapper}>
          <div className={Styles.testimonials__logo}>
            {item.logo && (
              <Image
                sharp={item.logoSharp}
                alt={item.logo.alt || item.author}
              />
            )}
          </div>
          <blockquote className={Styles.testimonials__blockquote}>
            <div className={Styles.testimonials__quote}>
              {item.quote && (
                <RichText render={item.quote} linkResolver={linkResolver} />
              )}
            </div>
            <cite className={Styles.testimonials__cite}>
              <span className={Styles.testimonials__author}>{item.author}</span>
              <span className={Styles.testimonials__position}>
                &nbsp; {item.position}
              </span>
            </cite>
          </blockquote>
        </div>
      </div>
    )
  })

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  }

  return (
    <Wrapper>
      <div
        className={cn(
          Styles.testimonials,
          theme === "Light"
            ? Styles.testimonialsLight
            : Styles.testimonialsDark,
          theme === "Light" ? "is-light" : "is-dark",
          "testimonials-slider"
        )}
      >
        <Slider {...settings} className={Styles.testimonials__container}>
          {content}
        </Slider>
      </div>
    </Wrapper>
  )
}

Testimonials.propTypes = {
  theme: PropTypes.oneOf(["Dark", "Light"]),
  items: PropTypes.array,
}

Testimonials.defaultProps = {
  theme: "Dark",
  items: [],
}

export default Testimonials
