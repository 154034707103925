import React from 'react';

const FlexibleSvg = () => {
    return (
        <svg width="48" height="29" viewBox="0 0 48 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M48 23V29H0V24.5C0 20.12 2.97 16.44 7 15.34V10.5C7 4.7 11.7 0 17.5 0C21.88 0 25.63 2.68 27.2 6.49C28.66 4.96 30.72 4 33 4C37.42 4 41 7.58 41 12V12.76C45.1 14.36 48 18.33 48 23Z" fill="url(#paint0_linear_315_1121)" />
            <path d="M29 15.0002C29 11.8872 26.155 9.43819 22.922 10.1132C20.966 10.5212 19.414 12.1452 19.076 14.1142C18.66 16.5322 19.985 18.6962 22 19.5782V26.0002H23.5C24.881 26.0002 26 24.8812 26 23.5002V19.5782C27.764 18.8062 29 17.0492 29 15.0002Z" fill="url(#paint1_linear_315_1121)" />
            <defs>
                <linearGradient id="paint0_linear_315_1121" x1="59.223" y1="-14.148" x2="-39" y2="84.0749" gradientUnits="userSpaceOnUse">
                    <stop stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
                <linearGradient id="paint1_linear_315_1121" x1="9.46397" y1="41.4882" x2="33.5479" y2="-0.225802" gradientUnits="userSpaceOnUse">
                    <stop offset="0.34" stop-color="white" stop-opacity="0.8" />
                    <stop offset="1" stop-color="white" stop-opacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};

export default FlexibleSvg;