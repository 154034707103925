import React from "react"
import PropTypes from "prop-types"
import Styles from "./checklist.module.scss"
import ChecklistItem from "../checklist-item/checklist-item"
import ButtonGroup from "../button-group/button-group"

const Checklist = ({ headline, buttonTitle, buttonLink, items }) => {
  const content = items.map((item, key) => {
    return (
      <li key={key.toString()} className={Styles.checklist__item}>
        <ChecklistItem title={item.title} />
      </li>
    )
  })

  return (
    <div className={Styles.checklist}>
      <h3 className={Styles.checklist__headline}>{headline}</h3>
      <ul className={Styles.checklist__list}>{content}</ul>
      {buttonTitle && buttonLink && (
        <ButtonGroup buttons={[{ title: buttonTitle, link: buttonLink }]} />
      )}
    </div>
  )
}

Checklist.propTypes = {
  headline: PropTypes.string,
  buttonTitle: PropTypes.string,
  buttonLink: PropTypes.object,
  items: PropTypes.array,
}

Checklist.defaultProps = {
  headline: "",
  buttonTitle: "",
  buttonLink: {},
  items: [],
}

export default Checklist
