import React from "react"
import ClientDescription from "../../components/client-description/client-description"

export default ({ slice }) => {
  return (
    <ClientDescription
      headline={slice.primary.headline}
      summary={slice.primary.summary}
      region={slice.primary.region}
      industry={slice.primary.industry}
    />
  )
}
