import React from "react"
import TestimonialsAlternative from "../../components/testimonials-alternative/testimonials-alternative"

export default ({ slice }) => {
  return (
    <TestimonialsAlternative
      heading={slice?.primary?.heading}
      items={slice.fields}
    />
  )
}
