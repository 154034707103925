import React from "react"
import GraphicHero from "../../components/graphic-hero/graphic-hero"

export default ({ slice }) => {
  const {
    headline,
    summary,
    cta_text,
    cta_link,
    feature_image,
    feature_imageSharp,
    thumbnail_image,
    thumbnail_imageSharp,
  } = slice.primary

  return (
    <GraphicHero
      headline={headline}
      summary={summary}
      ctaText={cta_text}
      ctaLink={cta_link}
      featureImageSharp={feature_imageSharp}
      featureImageAlt={feature_image?.alt}
      thumbnailImageSharp={thumbnail_imageSharp}
      thumbnailImageAlt={thumbnail_image?.alt}
    />
  )
}
