import React, { useState, useEffect } from "react"
import { RichText } from "prismic-reactjs"
import Styles from "./tech-spec.module.scss"
import cn from "classnames"

import Wrapper from "../layout/wrapper/wrapper"
import Title from "../../elements/title/title"

const TechSpec = ({ specs }) => {
  return (
    <Wrapper>
      {specs.map(spec => (
        <div className={Styles.spec}>
          <div className={Styles.heading}>
            <Title title={spec.heading} tag={"h3"} />
          </div>
          <div className={Styles.description}>
            <RichText render={spec.description} />
          </div>
          {spec.description__second_column_ && (
            <div className={Styles.description}>
              <RichText render={spec.description__second_column_} />
            </div>
          )}
        </div>
      ))}
    </Wrapper>
  )
}

export default TechSpec
